import React from 'react';
import * as styles from './TextInput.css';

const TextInput = ({
    label,
    value,
    onChange,
    required,
    name,
    placeholder,
    disabled,
    inputHeight,
}: {
    label?: string;
    value?: string | number | boolean | null;
    required: boolean;
    onChange: React.ChangeEventHandler;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    inputHeight?: string;
}): React.ReactElement => {
    return (
        <>
            {label != null && (
                <label htmlFor="input" className={styles.label}>
                    {label}
                    {required && label !== '' ? ' *' : ''}
                </label>
            )}

            <input
                className={
                    (required && value === '') || value === null
                        ? styles.inputFieldText.required
                        : styles.inputFieldText.standard
                }
                style={{ height: inputHeight }}
                type="text"
                value={value !== null && typeof value !== 'boolean' ? value : ''}
                onChange={onChange}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
            />
        </>
    );
};
TextInput.defaultProps = {
    label: '',
    value: '',
    name: undefined,
    placeholder: '',
    disabled: false,
    inputHeight: '',
};
export default TextInput;
