import React from 'react';
import * as styles from './PublishToggle.css';

const PublishToggle = ({
    id,
    label,
    checked,
    disabled,
    onChange,
    desc,
}: {
    id?: string;
    label: string;
    checked: boolean | undefined;
    disabled: boolean;
    onChange: React.ChangeEventHandler | undefined;
    desc?: string;
}): React.ReactElement => {
    return (
        <>
            <label htmlFor={`toggle${id}`} className={styles.toggleControl}>
                {label}
                <span className={styles.desc}>
                    <br />
                    {desc}
                </span>
                <input
                    id={`toggle${id}`}
                    className={styles.toggleSwitch}
                    type="checkbox"
                    onChange={disabled ? () => null : onChange}
                    checked={checked}
                />
                <span className={disabled ? styles.control.disabled : styles.control.active} />
            </label>
        </>
    );
};
PublishToggle.defaultProps = {
    id: '',
    desc: '',
};
export default PublishToggle;
