import clamp from 'lodash/clamp';
import findIndex from 'lodash/findIndex';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useState } from 'react';
import Arrow from '../../images/arrow.svg';
import * as styles from './PaginationSelect.css';

export interface SelectOption<T> {
    value: T;
    label: string;
}

function PaginationSelect<T>({
    label,
    options,
    value,
    onChange,
    desc,
    width,
    border,
}: {
    label?: string;
    options: SelectOption<T>[] | null;
    value: T;
    onChange: (v: T | null) => void;
    desc?: string;
    width?: string;
    border: boolean;
}): React.ReactElement {
    const [id] = useState(uniqueId('select-'));
    const [open, setOpen] = useState(false);
    const [selectIndex, setSelectIndex] = useState(0);

    useEffect(() => {
        setSelectIndex(findIndex(options, (o) => o.value === value));
    }, [options, value]);

    const onKeyDown = (e: React.KeyboardEvent): void => {
        e.preventDefault();
        switch (e.key) {
            case 'Enter':
            case 'NumpadEnter':
                onChange(options !== null ? options[selectIndex].value : null);
                setOpen(false);
                break;
            case 'ArrowDown':
                setSelectIndex(options !== null ? clamp(selectIndex + 1, 0, options.length - 1) : 0);
                setOpen(true);
                break;
            case 'ArrowUp':
                setSelectIndex(options !== null ? clamp(selectIndex - 1, 0, options.length - 1) : 0);
                setOpen(true);
                break;
            default:
        }
    };

    const activeIndex = findIndex(options, (o) => o.value === value);

    return (
        <>
            {label != null && <div className={styles.label}>{label}</div>}
            {desc && <div className={styles.description}>{desc}</div>}
            <div className={styles.selectBase} style={{ width: `${width !== null ? width : '20rem'}` }}>
                <label htmlFor={id} className={styles.textfield}>
                    <input
                        id={id}
                        readOnly
                        value={options !== null ? options[activeIndex].label : ''}
                        onFocus={() => setOpen(true)}
                        onBlur={() => setOpen(false)}
                        onKeyDown={onKeyDown}
                        className={styles.input}
                        style={{ border: `${border ? 'solid 0.063rem #b0b0b0l' : 'none'}` }}
                    />
                    <img
                        className={open ? styles.rotateArrow.active : styles.rotateArrow.standard}
                        src={Arrow}
                        alt="arrow"
                    />
                    <ul className={open ? styles.selectDropdown.open : styles.selectDropdown.closed}>
                        {options !== null &&
                            options.map((o, i) => {
                                let buttonClass = styles.li.default;
                                if (i === selectIndex) buttonClass = styles.li.selected;
                                if (o.value === value) buttonClass = styles.li.active;
                                return (
                                    <li key={o.label} className={buttonClass}>
                                        <button
                                            className={styles.buttonClass.default}
                                            onClick={() => {
                                                onChange(o.value);

                                                setOpen(false);
                                            }}
                                            type="button"
                                            aria-label={o.label}
                                        >
                                            {o.label}
                                        </button>
                                    </li>
                                );
                            })}
                    </ul>
                </label>
            </div>
        </>
    );
}

PaginationSelect.defaultProps = {
    label: undefined,
    desc: '',
    width: '',
};

export default PaginationSelect;
