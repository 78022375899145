import { useTranslation } from '@ubique-innovation/react-translations';
import React, { ChangeEvent, useState } from 'react';
import Pencil from '../../images/pencil-fill.svg';
import { Locale } from '../../types/Workspace';
import * as styles from './EditBlock.css';
import FleskButton from './FleskButton';
import Select, { SelectOption } from './Select';
import TextInput from './TextInput';

export enum Formats {
    INPUT,
    AREA,
    TOGGLE,
    SELECT,
    DROPDOWN,
}

export interface KeyValue<T> {
    name: string;
    label?: string;
    value: T | Locale | null;
    required: boolean;
    format: Formats;
    onChange: (d: string | number | boolean | ChangeEvent | null) => void;
    disableInput?: boolean;
    hide?: boolean;
    placeholder?: string;
    options?: SelectOption<string | number | boolean | ChangeEvent | null>[];
}

const EditBlock = ({
    id,
    blockTitle,
    blockDesc,
    blockSets,
    reset,
}: {
    id: string;
    blockTitle: string;
    blockDesc?: string;
    blockSets: KeyValue<string | number | boolean>[];
    reset: () => void;
}): React.ReactElement => {
    const [edit, setEdit] = useState(false);
    const { t } = useTranslation();
    const showEditMode = (): void => {
        setEdit(true);
        if (edit) {
            setEdit(false);
        } else {
            setEdit(true);
        }
    };

    const getLanguage = (lang: string): string => {
        switch (lang) {
            case Locale.DE:
                return t('flesk.language.de');
            case Locale.EN:
                return t('flesk.language.en');
            case Locale.FR:
                return t('flesk.language.fr');
            default:
                return '';
        }
    };

    return (
        <div>
            {edit ? (
                <div className={styles.editContainer}>
                    <div className={styles.header}>
                        <p>{blockTitle}</p>
                    </div>
                    <p className={styles.desc}>{blockDesc}</p>
                    <div className={styles.infoContainer}>
                        {blockSets.map((e) => (
                            <div key={e.name}>
                                {!e.hide && (
                                    <div className={styles.keyValueContainer}>
                                        <div className={styles.key}>{e.label}</div>
                                        {e.format === Formats.INPUT && !e.disableInput && (
                                            <TextInput
                                                name={e.name}
                                                value={e.value}
                                                required={e.required}
                                                onChange={e.onChange}
                                                placeholder={e.placeholder}
                                            />
                                        )}
                                        {e.format === Formats.TOGGLE && (
                                            // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                            <label className={styles.mwstLabel} htmlFor={e.name}>
                                                <div className={styles.optionTitle}>
                                                    <input
                                                        id={e.name}
                                                        name={e.name}
                                                        className={styles.mwstCheckbox}
                                                        type="checkbox"
                                                        onChange={e.onChange}
                                                        checked={!e.value}
                                                        required={e.required}
                                                    />
                                                    <span className={styles.mwstBox} />
                                                </div>
                                            </label>
                                        )}
                                        {e.format === Formats.DROPDOWN && (
                                            <Select
                                                options={e.options || []}
                                                value={e.value}
                                                onChange={e.onChange}
                                                border
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className={styles.btns}>
                            <FleskButton
                                label={t('flesk.btn.cancel')}
                                darkBtn={false}
                                onClick={() => {
                                    reset();
                                    setEdit(false);
                                }}
                            />
                            <FleskButton label={t('flesk.btn.save')} darkBtn id={id} submit />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.container}>
                    <div className={styles.header}>
                        <p>{blockTitle}</p>
                        <button type="button" className={styles.editButton} onClick={showEditMode}>
                            <img src={Pencil} alt="Edit Button" />
                        </button>
                    </div>
                    <p className={styles.desc}>{blockDesc}</p>
                    <div className={styles.infoContainer}>
                        {blockSets.map((e) => (
                            <div key={e.name}>
                                {!e.hide && (
                                    <div className={styles.keyValueContainer} key={e.name}>
                                        <div className={styles.key}>{e.label}</div>
                                        {e.value && e.value !== '' && e.name !== 'correspondenceLanguage' && (
                                            <div className={styles.value}>{e.value}</div>
                                        )}
                                        {e.value === '' && (
                                            <div className={styles.empty}>{t('account.empty.input')}</div>
                                        )}
                                        {e.value === null && (
                                            <div className={styles.empty}>{t('account.empty.input')}</div>
                                        )}
                                        {e.name === 'correspondenceLanguage' && typeof e.value === 'string' && (
                                            <div className={styles.value}>{getLanguage(e.value)}</div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

EditBlock.defaultProps = {
    blockDesc: '',
};

export default EditBlock;
