import { useTranslation } from '@ubique-innovation/react-translations';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import ArrowLeft from '../../images/arrow-left-line.svg';
import ContactDetailsIcon from '../../images/company/contact-details.svg';
import CreditsIcon from '../../images/company/credits.svg';
import UserIcon from '../../images/company/user.svg';
import BookingIcon from '../../images/sidebar/bookings.svg';
import { Pathname } from '../../types/Pathname';
import * as styles from './CompanySidebar.css';

const NavLink = ({
    text,
    href,
    icon,
    onClick,
    incomplete,
}: {
    text: string;
    href: Pathname;
    icon: string;
    onClick?: React.MouseEventHandler;
    incomplete?: boolean;
}): React.ReactElement => {
    const location = useLocation();
    return (
        <>
            <li className={styles.navListItem}>
                <Link
                    className={
                        location.pathname === `/company/${href}` ? styles.navLink.active : styles.navLink.standard
                    }
                    to={href}
                    onClick={onClick}
                >
                    <img className={styles.navIcon} src={icon} alt="" />
                    <div
                        className={
                            location.pathname === `/company/${href}`
                                ? styles.navListDiv.active
                                : styles.navListDiv.standard
                        }
                    >
                        <div className={styles.btnText}>
                            {text}
                            {incomplete && <span className={styles.redDot} />}
                        </div>
                    </div>
                </Link>
            </li>
        </>
    );
};

NavLink.defaultProps = { onClick: undefined, incomplete: false };

const CompanySidebar = ({ incomplete }: { incomplete: boolean }): React.ReactElement => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <>
            <ul className={styles.sidebarUl}>
                {location.pathname !== `${Pathname.SUBSCRIPTION}${Pathname.CREDITS}/${Pathname.PURCHASE}` ? (
                    <>
                        <div className={styles.navItems}>
                            <div className={styles.headerTitle}>{t('company.nav.title.abo')}</div>
                            <NavLink text={t('company.nav.credits')} icon={CreditsIcon} href={Pathname.CREDITS} />
                            <NavLink text={t('company.nav.user')} icon={UserIcon} href={Pathname.USER} />
                            <div className={styles.headerTitle}>{t('company.nav.title.use')}</div>
                            <NavLink text={t('company.nav.booking')} icon={BookingIcon} href={Pathname.BOOKING} />{' '}
                            <div className={styles.headerTitle}>{t('company.nav.title.account')}</div>
                            <NavLink
                                text={t('company.nav.contactDetails')}
                                icon={ContactDetailsIcon}
                                href={Pathname.CONTACT}
                                incomplete={incomplete}
                            />
                        </div>
                    </>
                ) : (
                    <button type="button" onClick={() => navigate(Pathname.CREDITS)} className={styles.backBtn}>
                        <img src={ArrowLeft} alt="arrow-left" />
                        {t('back.btn')}
                    </button>
                )}
            </ul>
        </>
    );
};

export default CompanySidebar;
