import React from 'react';
import alertIcon from '../../images/alert-line.svg';
import * as styles from './ErrorMessage.css';

const ErrorMessage = ({
    text,
    textThin,
    width,
}: {
    text: string;
    textThin?: string;
    width: number;
}): React.ReactElement => {
    return (
        <div className={styles.feedback} style={{ width: `${width}rem` }}>
            <img className={styles.alertIcon} src={alertIcon} alt="" />
            <h4 className={styles.imageWarning}>
                <b>{text}</b>
                {textThin}
            </h4>
        </div>
    );
};
ErrorMessage.defaultProps = {
    textThin: '',
};
export default ErrorMessage;
