import { selector } from 'recoil';
import { companyIdState } from './CompanyState';
import { workspaceIdState } from './WorkspaceState';

export interface UrlParams {
    workspaceId: number | undefined;
    companyId: number | undefined;
}

export const urlParamsState = selector({
    key: 'urlParamsState',
    get: ({ get }): UrlParams => {
        const workspaceId = get(workspaceIdState);
        const companyId = get(companyIdState);

        return {
            workspaceId,
            companyId,
        };
    },
});
