import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { TranslationProvider } from '@ubique-innovation/react-translations';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

import './index.css';
import { RecoilRoot } from 'recoil';
import translationsDe from './translations/de/translations.json';
import translationsFr from './translations/fr/translations.json';
import translationsEn from './translations/en/translations.json';
import App, { IDP_REDIRECT_PATH } from './containers/App';

const resources = {
    de: translationsDe,
    fr: translationsFr,
    en: translationsEn,
};

function getLanguage(): string {
    if (localStorage.getItem('lang') === null) {
        return navigator.language.substring(0, 2);
    }

    const localLang = localStorage.getItem('lang');
    if (localLang) {
        return localLang;
    }

    return '';
}

const authorityUrl = process.env.REACT_APP_ISSUER_URL ? process.env.REACT_APP_ISSUER_URL : '/issuer';
const clientId = 'flesk-web';

const authConfig: AuthProviderProps = {
    authority: authorityUrl,
    client_id: clientId,
    redirect_uri: `${window.location.origin}${IDP_REDIRECT_PATH}`,
    response_mode: 'fragment',
    scope: 'openid profile',
    post_logout_redirect_uri: window.location.origin,
};

ReactDOM.render(
    <React.StrictMode>
        <TranslationProvider resources={resources} defaultLang={getLanguage()}>
            <RecoilRoot>
                <BrowserRouter>
                    <AuthProvider {...authConfig}>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </RecoilRoot>
        </TranslationProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
