import { useTranslation } from '@ubique-innovation/react-translations';
import React from 'react';
import * as styles from './SwitchToggle.css';

const SwitchToggle = ({
    id,
    checked,
    onChange,
}: {
    id?: string;
    checked: boolean;
    onChange: React.ChangeEventHandler;
}): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <label htmlFor={`toggle${id}`} className={styles.toggleControl}>
            <input
                id={`toggle${id}`}
                className={styles.toggleSwitch}
                type="checkbox"
                onChange={onChange}
                checked={checked}
            />
            <span className={styles.control}>
                <div className={styles.label}>
                    <div className={styles.text} style={{ color: checked ? '#232443' : 'white' }}>
                        {t('tab.abonnement')}
                    </div>
                    <div className={styles.text} style={{ color: checked ? 'white' : '#232443' }}>
                        {t('tab.workspaces')}
                    </div>
                </div>
            </span>
        </label>
    );
};
SwitchToggle.defaultProps = {
    id: '',
};
export default SwitchToggle;
