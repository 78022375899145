import React, { useState } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import { TimePicker } from 'antd';
import 'antd/dist/antd.min.css';
import moment, { Moment } from 'moment';

import { TimeSlots } from '../../types/Workspace';
import * as styles from './OpeningHours.css';
import Pencil from '../../images/pencil-fill.svg';
import SectionTitle from './SectionTitle';
import FleskButton from './FleskButton';

const Weekday = ({
    day,
    label,
    workspace,
    edit,
    slots,
    setSlots,
}: {
    day: 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
    label: string;
    workspace: TimeSlots;
    edit: boolean;
    slots: TimeSlots;
    setSlots: (d: TimeSlots) => void;
}): React.ReactElement => {
    const { t } = useTranslation();
    const checkIfExists = (): boolean => {
        return (
            workspace[`${day}1From`] !== null ||
            workspace[`${day}1To`] !== null ||
            workspace[`${day}2From`] !== null ||
            workspace[`${day}2To`] !== null
        );
    };

    const checkMorning = (): boolean => {
        return workspace[`${day}1From`] !== null && workspace[`${day}1To`] !== null;
    };

    const checkAfternoon = (): boolean => {
        return workspace[`${day}2From`] !== null && workspace[`${day}2To`] !== null;
    };

    const morning = checkMorning() ? (
        <span>
            {workspace[`${day}1From`]}–{workspace[`${day}1To`]}{' '}
        </span>
    ) : (
        ''
    );

    const separator = checkMorning() && checkAfternoon() ? ' , ' : '';

    const afternoon = checkAfternoon() ? (
        <span>
            {workspace[`${day}2From`]}-{workspace[`${day}2To`]}{' '}
        </span>
    ) : (
        ''
    );

    const getTime = (time: Moment | null): Moment | null => {
        if (!time?.isValid()) {
            return null;
        }
        return time;
    };

    return (
        <>
            <div className={styles.openingHour}>
                <h4 className={checkIfExists() ? styles.weekDay.standard : styles.weekDay.disabled}>{label}</h4>
                {edit ? (
                    <>
                        <TimePicker.RangePicker
                            name={`localizations.${day}1From`}
                            size="small"
                            format="HH:mm"
                            minuteStep={15}
                            defaultValue={[
                                getTime(moment(workspace[`${day}1From`], ' HH:mm')),
                                getTime(moment(workspace[`${day}1To`], 'HH:mm')),
                            ]}
                            onOk={(time) => {
                                setSlots(slots);
                                const s = { ...slots };
                                s[`${day}1From`] = moment(time?.[0]).format('HH:mm');
                                s[`${day}1To`] = moment(time?.[1]).format('HH:mm');
                                setSlots(s);
                            }}
                            onChange={(time) => {
                                const s = { ...slots };
                                if (time === null) {
                                    s[`${day}1From`] = null;
                                    s[`${day}1To`] = null;
                                }
                                setSlots(s);
                            }}
                        />
                        <div className={styles.line}>
                            <p>–</p>
                        </div>
                        <TimePicker.RangePicker
                            size="small"
                            format="HH:mm"
                            minuteStep={15}
                            defaultValue={[
                                getTime(moment(workspace[`${day}2From`], 'HH:mm')),
                                getTime(moment(workspace[`${day}2To`], 'HH:mm')),
                            ]}
                            onOk={(time) => {
                                // setSlots(slots);
                                const s = { ...slots };
                                s[`${day}2From`] = moment(time?.[0]).format('HH:mm');
                                s[`${day}2To`] = moment(time?.[1]).format('HH:mm');
                                setSlots(s);
                            }}
                            allowClear
                            onChange={(time) => {
                                const s = { ...slots };
                                if (time === null) {
                                    s[`${day}2From`] = null;
                                    s[`${day}2To`] = null;
                                }
                                setSlots(s);
                            }}
                        />
                    </>
                ) : (
                    <>
                        {checkIfExists() ? (
                            <div>
                                {morning}
                                {separator}
                                {afternoon}
                            </div>
                        ) : (
                            <div className={styles.closed}>{t('flesk.openinghours.closed')}</div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

const OpeningHours = ({
    workspace,
    onChange,
}: {
    workspace: TimeSlots;
    onChange: (days: TimeSlots) => void;
}): React.ReactElement => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [slots, setSlots] = useState<TimeSlots>(workspace);
    return (
        <div className={edit ? styles.openingHours.edit : styles.openingHours.standard}>
            <div className={styles.openingHeader}>
                <SectionTitle title={t('myspace.workspace.title.openinghours')} />
                {edit ? (
                    ''
                ) : (
                    <button type="button" className={styles.editBtn} onClick={() => setEdit(true)}>
                        <img src={Pencil} alt="Edit Button" />
                    </button>
                )}
            </div>
            {edit ? (
                <>
                    <p className={styles.desc}>{t('myspace.workspace.openinghours.description')}</p>
                    <Weekday
                        day="mon"
                        label={t('myspace.workspace.openinghours.monday')}
                        workspace={workspace}
                        edit
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="tue"
                        label={t('myspace.workspace.openinghours.tuesday')}
                        workspace={workspace}
                        edit
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="wed"
                        label={t('myspace.workspace.openinghours.wednesday')}
                        workspace={workspace}
                        edit
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="thu"
                        label={t('myspace.workspace.openinghours.thursday')}
                        workspace={workspace}
                        edit
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="fri"
                        label={t('myspace.workspace.openinghours.friday')}
                        workspace={workspace}
                        edit
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="sat"
                        label={t('myspace.workspace.openinghours.saturday')}
                        workspace={workspace}
                        edit
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="sun"
                        label={t('myspace.workspace.openinghours.sunday')}
                        workspace={workspace}
                        edit
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <div className={styles.saveBtn}>
                        <FleskButton label={t('flesk.btn.cancel')} darkBtn={false} onClick={() => setEdit(false)} />

                        <FleskButton
                            darkBtn
                            label={t('flesk.btn.save')}
                            onClick={() => {
                                onChange(slots);
                                setEdit(false);
                            }}
                        />
                    </div>
                </>
            ) : (
                <>
                    <p className={styles.desc}>{t('myspace.workspace.openinghours.description')}</p>
                    <Weekday
                        day="mon"
                        label={t('myspace.workspace.openinghours.monday')}
                        workspace={workspace}
                        edit={false}
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="tue"
                        label={t('myspace.workspace.openinghours.tuesday')}
                        workspace={workspace}
                        edit={false}
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="wed"
                        label={t('myspace.workspace.openinghours.wednesday')}
                        workspace={workspace}
                        edit={false}
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="thu"
                        label={t('myspace.workspace.openinghours.thursday')}
                        workspace={workspace}
                        edit={false}
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="fri"
                        label={t('myspace.workspace.openinghours.friday')}
                        workspace={workspace}
                        edit={false}
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="sat"
                        label={t('myspace.workspace.openinghours.saturday')}
                        workspace={workspace}
                        edit={false}
                        slots={slots}
                        setSlots={setSlots}
                    />
                    <Weekday
                        day="sun"
                        label={t('myspace.workspace.openinghours.sunday')}
                        workspace={workspace}
                        edit={false}
                        slots={slots}
                        setSlots={setSlots}
                    />
                </>
            )}
        </div>
    );
};
export default OpeningHours;
