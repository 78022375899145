import { Row } from '@tanstack/react-table';
import { Booking, bookingOptionOrder, CompanyBooking } from '../types/Bookings';
import { productTypeOrder } from '../types/Workspace';

/**
 * Sorting function to sort bookings by date, considering booking options as well.
 * @param rowA First booking to compare
 * @param rowB Second booking to compare
 * @param columnId unused
 * @returns -1 if a should be before b, 1 if b should be before a, 0 if they are equal
 */
export const sortTableBookingsDateTimeColumn = (
    rowA: Row<Booking> | Row<CompanyBooking>,
    rowB: Row<Booking> | Row<CompanyBooking>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    columnId: string,
): number => {
    const bookingA = rowA.original;
    const bookingB = rowB.original;

    const bookingAStart = bookingA.startAt;
    const bookingBStart = bookingB.startAt;
    if (bookingAStart !== bookingBStart) {
        // If startAt of the bookings is different, sort by startAt
        return bookingAStart < bookingBStart ? -1 : 1;
    }

    const bookingAEnd = bookingA.endAt;
    const bookingBEnd = bookingB.endAt;
    if (bookingAEnd !== bookingBEnd) {
        // If endAt of the bookings is different, sort by endAt
        return bookingAEnd < bookingBEnd ? -1 : 1;
    }

    const bookingABookingOption = bookingA.bookingOption;
    const bookingBBookingOption = bookingB.bookingOption;
    if (bookingABookingOption !== bookingBBookingOption) {
        // If bookingOption of the bookings is different, sort by bookingOption
        return bookingOptionOrder[bookingABookingOption] < bookingOptionOrder[bookingBBookingOption] ? -1 : 1;
    }

    // sort by bookingId as the tiebreak
    return bookingA.bookingId < bookingB.bookingId ? -1 : 1;
};

/**
 * Sorting function to sort bookings by productType.
 * @param rowA First booking to compare
 * @param rowB Second booking to compare
 * @param columnId unused
 * @returns -1 if a should be before b, 1 if b should be before a, 0 if they are equal
 */
export const sortTableBookingsProductTypeColumn = (
    rowA: Row<Booking> | Row<CompanyBooking>,
    rowB: Row<Booking> | Row<CompanyBooking>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    columnId: string,
): number => {
    const bookingA = rowA.original;
    const bookingB = rowB.original;

    // sort by productType
    return productTypeOrder[bookingA.productType] < productTypeOrder[bookingB.productType] ? -1 : 1;
};
