import { useTranslation } from '@ubique-innovation/react-translations';
import React from 'react';
import { Link } from 'react-router-dom';
import InfoIcon from '../../../images/info-icon.svg';
import { AboCredits } from '../../../types/Credits';
import { Pathname } from '../../../types/Pathname';
import * as styles from './CreditsTab.css';

const CreditsTab = ({ credits }: { credits: AboCredits }): React.ReactElement => {
    const { t } = useTranslation();

    // const columns = React.useMemo<ColumnDef<TopUpPurchase>[]>(
    //     () => [
    //         {
    //             header: t('bookings.category.name'),
    //             accessorKey: 'name',
    //             // cell: (info) => info.getValue(),
    //         },
    //         {
    //             header: t('bookings.category.product'),
    //             accessorKey: 'productType',
    //             // cell: (info) => productString[info.getValue() as ProductType],
    //         },
    //         {
    //             header: t('booking.credits'),
    //             accessorKey: 'credits',
    //             // cell: (info) => (info.getValue() === null ? t('bookings.unknown') : info.getValue()),
    //         },
    //         {
    //             header: t('bookings.category.date'),
    //             // sortingFn: sortTableBookingsColumn,
    //             // accessorFn: (info) => `  ${getDate(info.startAt, info.endAt, info.bookingOption)}
    //             // ${getTime(info.startAt, info.endAt, info.bookingOption)}`,
    //         },
    //     ],
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [],
    // );

    return (
        <div className={styles.wrapper}>
            <div className={styles.inputForm}>
                <div className={styles.header}>
                    <div className={styles.text({ color: 'blueDark' })}>{t('credits.title')}</div>
                </div>
                <div className={styles.creditsWrapper}>
                    <div className={styles.creditsOverview}>
                        <div className={styles.whiteWrapper}>
                            <div className={styles.headerWrapper}>
                                <div className={styles.text({ size: 'xs', weight: 'bold', letterSpacing: 1 })}>
                                    {t('credits.available').toUpperCase()}
                                </div>
                                <div className={styles.text({ color: 'blueDark', size: 'l', weight: 'bold' })}>
                                    {credits.aboName}
                                </div>
                                {/* <p className={styles.text({ size: 'm', weight: 'bold', color: 'greenLime' })}>
                                    80 Credits
                                </p> */}
                                {/* <p className={styles.text({ size: 's', weight: 'normal' })}>Rechnung · Monatlich</p>
                                <p className={styles.text({ size: 's', weight: 'normal' })}>
                                    Wird verlängert am: 13.03.2024
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.creditsOverview}>
                        <div className={styles.whiteWrapper}>
                            <div className={styles.headerWrapper}>
                                <div
                                    className={styles.text({ size: 'xs', weight: 'bold', letterSpacing: 1 })}
                                    style={{ paddingBottom: '0.5rem' }}
                                >
                                    {t('booking.credits').toUpperCase()}
                                </div>
                                <div
                                    className={styles.text({
                                        color: 'lightBlue',
                                        size: 'l',
                                        weight: 'bold',
                                        display: 'flex',
                                        alignItems: 'baseLine',
                                    })}
                                >
                                    {credits.availableCredits} {t('booking.credits')}
                                </div>
                                <p className={styles.text({ size: 's', weight: 'normal' })}>{t('still.available')}</p>

                                <Link className={styles.btn} to={Pathname.PURCHASE}>
                                    {t('buy.credits.btn')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.infoBox}>
                    <img className={styles.infoIcon} src={InfoIcon} alt="info-icon" />
                    {`${t('credits.info.text')}`}
                    <p className={styles.text({ size: 's', weight: 'normal' })}>{t('credits.info.text1')}</p>
                    <a className={styles.link} href={`mailto: ${t('credits.info.mail')}`}>
                        {' '}
                        {t('credits.info.mail')}.
                    </a>
                </div>
                {/* <div>
                    <p className={styles.text({ size: 'm', weight: 'bold' })}>Top-Up Käufe</p>
                    {/* <Table
                        sort={t('bookings.category.date')}
                        withDetail
                        {...{
                            data,
                            columns,
                        }}
                        setShowDetailView={setShowDetailView}
                    /> */}
                {/* </div> */}
            </div>
        </div>
    );
};
export default CreditsTab;
