import { useEffect } from 'react';

interface TallyWindow extends Window {
    Tally?: {
        loadEmbeds(): void;
        // Define other properties and methods if needed
    };
}

const InvoicePurchase = (): React.ReactElement => {
    // The code below will load the embed
    useEffect(() => {
        const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

        const load = (): void => {
            // Load Tally embeds
            if (typeof (window as TallyWindow).Tally !== 'undefined') {
                (window as TallyWindow).Tally?.loadEmbeds();
                return;
            }

            // Fallback if window.Tally is not available
            document.querySelectorAll<HTMLIFrameElement>('iframe[data-tally-src]:not([src])').forEach((iframeEl) => {
                const iframeCopy = iframeEl;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                iframeCopy.src = iframeCopy.dataset.tallySrc!;
            });
        };

        // If Tally is already loaded, load the embeds
        if (typeof (window as TallyWindow).Tally !== 'undefined') {
            load();
            return;
        }

        // If the Tally widget script is not loaded yet, load it
        if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
            const script = document.createElement('script');
            script.src = widgetScriptSrc;
            script.onload = load;
            script.onerror = load;
            document.body.appendChild(script);
        }
    }, []);

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <iframe
            data-tally-src="https://tally.so/embed/mJzM0d?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="569"
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
            title="Top-Up Credits bestellen"
        />
    );
};

export default InvoicePurchase;
