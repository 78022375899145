import React from 'react';
import * as styles from './LanguageCheckbox.css';

const LanguageCheckbox = ({
    label,
    checked,
    onChange,
}: {
    label: string;
    checked: boolean;
    onChange: React.ChangeEventHandler;
}): React.ReactElement => {
    return (
        <>
            <label className={styles.languageLabel} htmlFor={label}>
                <div className={styles.optionTitle}>
                    {label}
                    <input
                        id={label}
                        className={styles.languageCheckbox}
                        key={label}
                        type="checkbox"
                        onChange={onChange}
                        checked={checked}
                    />
                    <span className={styles.checkbox} />
                </div>
            </label>
        </>
    );
};

export default LanguageCheckbox;
