import produce from 'immer';
import set from 'lodash/set';

type ReducerFuncArgs<T> = (base: T) => void;
type ReducerPartialArgs<T> = Partial<T>;
type ReducerPathArgs = {
    _path: string | string[];
    _value: string | number | boolean;
};

export type FormReducerArgs<T> = ReducerFuncArgs<T> | ReducerPartialArgs<T> | ReducerPathArgs;
export type FormReducerDispatch<T> = (a: FormReducerArgs<T>) => void;

const isFunctionArgs = <T>(a: FormReducerArgs<T>): a is ReducerFuncArgs<T> => typeof a === 'function';
const isPathArgs = <T>(a: ReducerFuncArgs<T> | ReducerPartialArgs<T> | ReducerPathArgs): a is ReducerPathArgs =>
    !isFunctionArgs(a) && '_path' in a && '_value' in a;

// eslint-disable-next-line @typescript-eslint/ban-types
const formReducer = <T extends Object>(
    state: T,
    updateArgs: ReducerFuncArgs<T> | ReducerPartialArgs<T> | ReducerPathArgs,
): T => {
    if (isFunctionArgs(updateArgs)) {
        return produce(state, updateArgs);
    }
    if (isPathArgs(updateArgs)) {
        return produce(state, (draft) => {
            const { _path: path, _value: value } = updateArgs;
            set(draft, path, value);
        });
    }
    return { ...state, ...updateArgs };
};

export default formReducer;
