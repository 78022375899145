import { useTranslation } from '@ubique-innovation/react-translations';
import filter from 'lodash/filter';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SettingsIcon from '../../images/company/contact-details.svg';
import InfosIcons from '../../images/sidebar/Angebot.svg';
import BookingsIcon from '../../images/sidebar/bookings.svg';
import OfferIcon from '../../images/sidebar/Infos.svg';
import WorkspacesIcon from '../../images/sidebar/workspaces.svg';
import * as styles from './Sidebar.css';

import useWorkspaceIdsFromToken from '../../hooks/useWorkspaceIdsFromToken';
import { Pathname } from '../../types/Pathname';
import { Workspaces } from '../../types/Workspace';

const NavLink = ({
    text,
    href,
    icon,
    onClick,
    incomplete,
}: {
    text: string;
    href: Pathname;
    icon: string;
    onClick?: React.MouseEventHandler;
    incomplete?: boolean;
}): React.ReactElement => {
    const location = useLocation();
    return (
        <>
            <li className={styles.navListItem}>
                <Link
                    className={location.pathname === `/space/${href}` ? styles.navLink.active : styles.navLink.standard}
                    to={href}
                    onClick={onClick}
                >
                    <img className={styles.navIcon} src={icon} alt="" />
                    <div
                        className={
                            location.pathname === `/space/${href}`
                                ? styles.navListDiv.active
                                : styles.navListDiv.standard
                        }
                    >
                        <div className={styles.btnText}>
                            {text}
                            {incomplete && <span className={styles.redDot} />}
                        </div>
                    </div>
                </Link>
            </li>
        </>
    );
};

NavLink.defaultProps = { onClick: undefined, incomplete: false };

const Sidebar = ({
    workspaces,
    dirtyForm,
    setDirtyForm,
    workspaceId,
    incomplete,
    setShowWorkspace,
}: {
    workspaces: Workspaces[];
    dirtyForm: boolean;
    setDirtyForm: (d: boolean) => void;
    workspaceId: number | undefined;
    incomplete: boolean;
    setShowWorkspace: (d: boolean) => void;
}): React.ReactElement => {
    const { t } = useTranslation();
    const workspaceIdsFromToken = useWorkspaceIdsFromToken();
    let onClick;

    if (dirtyForm) {
        onClick = (e: React.MouseEvent) => {
            if (!window.confirm(t('messages.confirm.dirtyForm'))) {
                e.preventDefault();
            }
            setDirtyForm(false);
        };
    }

    const getWorkspaceTitle = (id: number | string | undefined): string => {
        if (id == null) {
            return '';
        }
        const numb = typeof id === 'string' ? parseInt(id, 10) : id;
        if (workspaces && numb) {
            const comp = filter(workspaces, (l) => l.workspaceId === numb);
            return comp[0].title;
        }
        return '';
    };

    return (
        <>
            <ul className={styles.sidebarUl}>
                {workspaceIdsFromToken?.length === 1 ? (
                    <div className={styles.btn.disabled}>
                        <div className={styles.workspaceBtnIcon}>
                            {' '}
                            <img src={WorkspacesIcon} alt="Workspace" />
                        </div>
                        <div className={styles.workspaceBtnText}> {getWorkspaceTitle(workspaceId)}</div>
                    </div>
                ) : (
                    <>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => setShowWorkspace(true)}
                            onKeyDown={() => setShowWorkspace(true)}
                            className={styles.btn.standard}
                        >
                            <div className={styles.workspaceBtnIcon}>
                                {' '}
                                <img src={WorkspacesIcon} alt="Workspaces Button" />
                            </div>
                            <div className={styles.workspaceBtnText}> {getWorkspaceTitle(workspaceId)}</div>
                        </div>
                    </>
                )}

                <div className={styles.navItems}>
                    <div className={styles.headerTitle}>{t('space.nav.title.usage')}</div>
                    <NavLink
                        text={t('navigation.bookings')}
                        icon={BookingsIcon}
                        href={Pathname.WORKSPACEBOOKING}
                        onClick={onClick}
                    />
                    <div className={styles.headerTitle}>{t('space.nav.title.workspace').toUpperCase()}</div>
                    <NavLink
                        text={t('space.sidebar.info')}
                        icon={InfosIcons}
                        href={Pathname.MYSPACE}
                        onClick={onClick}
                    />{' '}
                    <NavLink text={t('space.sidebar.offer')} icon={OfferIcon} href={Pathname.OFFER} onClick={onClick} />
                    <div className={styles.headerTitle}>{t('company.nav.title.account')}</div>
                    <NavLink
                        text={t('navigation.settings')}
                        icon={SettingsIcon}
                        href={Pathname.SETTINGS}
                        onClick={onClick}
                        incomplete={incomplete}
                    />
                </div>
            </ul>
        </>
    );
};

export default Sidebar;
