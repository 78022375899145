import React, { useCallback, useEffect, useReducer, useState } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import produce from 'immer';
import { useTranslation } from '@ubique-innovation/react-translations';

import formReducer, { FormReducerArgs } from '../../../reducers/formReducer';
import { Locale, LocalWorkspaceImage, TimeSlots, Workspace } from '../../../types/Workspace';
import WorkspaceTab from './WorkspaceTab';
import FormControls from '../../templates/FormControls';

const secondLanguagesFromWorkspace = (workspace: Workspace): Locale[] => {
    return filter(
        Object.values(Locale),
        (lang) => lang !== workspace.primaryLang && workspace.localizations[lang] !== undefined,
    );
};

const InfosForm = ({
    workspace,
    onSave,
    setDirty,
    savingError,
    openingHours,
    setOpeningHours,
}: {
    workspace: Workspace;
    onSave: (w: Workspace, localImages?: LocalWorkspaceImage[]) => void;
    setDirty: (d: boolean) => void;
    savingError: string | undefined;
    openingHours: TimeSlots;
    setOpeningHours: (d: TimeSlots) => void;
}): React.ReactElement => {
    const [localImages, setLocalImages] = useState<LocalWorkspaceImage[]>([]);
    const [valid, setValid] = useState(false);
    const [secondLanguages, setSecondLanguages] = useState<Locale[]>(secondLanguagesFromWorkspace(workspace));
    const [formState, dispatchFormState] = useReducer<
        (state: Workspace, updateArgs: FormReducerArgs<Workspace>) => Workspace
    >(formReducer, workspace);
    const { t } = useTranslation();
    let v = false;

    const validateForm = (fState: Workspace): boolean => {
        v = !(fState.title === null || fState.title === '');
        return v;
    };

    const resetForm = useCallback(() => {
        dispatchFormState(workspace);
        setSecondLanguages(secondLanguagesFromWorkspace(workspace));
        setLocalImages([]);
        setDirty(false);
    }, [workspace, setDirty]);

    useEffect(() => {
        resetForm();
    }, [workspace, resetForm]);

    useEffect(() => {
        setValid(validateForm(formState));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    const handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (!valid) {
            alert(t('messages.error.invalidForm'));
            return;
        }
        const updatedWorkspace = produce(formState, (draft) => {
            Object.values(Locale).forEach((lang) => {
                if (!includes(secondLanguages, lang) && draft.primaryLang !== lang) {
                    draft.localizations[lang] = undefined;
                }
            });
        });
        onSave(updatedWorkspace, localImages);
    };

    return (
        <>
            <WorkspaceTab
                formState={formState}
                dispatch={dispatchFormState}
                localImages={localImages}
                setLocalImages={setLocalImages}
                secondLanguages={secondLanguages}
                setSecondLanguages={setSecondLanguages}
                onSubmit={handleSubmit}
                setDirty={setDirty}
                openingHours={openingHours}
                setOpeningHours={setOpeningHours}
            />
            <FormControls id="workspace" onReset={resetForm} formValid={valid} errorMessage={savingError} />
        </>
    );
};

export default InfosForm;
