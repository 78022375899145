import React, { useCallback, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import max from 'lodash/max';
import { useTranslation } from '@ubique-innovation/react-translations';

import * as styles from './ImageInput.css';
import MediaIcon from '../../images/icon-media.svg';
import alertIcon from '../../images/alert-line.svg';

import BinIcon from '../../images/icon-bin.svg';
import ShiftUpIcon from '../../images/ic-chevron-left.svg';
import ShiftDownIcon from '../../images/ic-chevron-right.svg';
import CheckIcon from '../../images/ic-check-line.svg';
import { isLocalWorkspaceImage, LocalWorkspaceImage, WorkspaceImage } from '../../types/Workspace';

const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
const maxFileSize = 5000000;

enum ValidationResult {
    OK = 'ok',
    WRONG_FORMAT = 'messages.error.wrongImageType',
    TOO_BIG = 'messages.error.fileTooBig',
}

const validateFile = (file: File): ValidationResult => {
    if (!includes(allowedImageTypes, file.type)) {
        return ValidationResult.WRONG_FORMAT;
    }
    if (file.size > maxFileSize) {
        return ValidationResult.TOO_BIG;
    }
    return ValidationResult.OK;
};

const reSortImages = (
    images: (WorkspaceImage | LocalWorkspaceImage)[],
    changedIdx?: number,
    shiftUp?: boolean,
): [WorkspaceImage[], LocalWorkspaceImage[]] => {
    const newImages: (WorkspaceImage | LocalWorkspaceImage)[] = [];
    if (changedIdx != null && shiftUp != null && changedIdx < images.length) {
        sortBy(images, (img) => img.sortKey).forEach((img, i) => {
            if (i === changedIdx) {
                const newKey = shiftUp ? i : i + 2;
                newImages.push({ ...img, sortKey: newKey });
            } else if (i === changedIdx - 1 && shiftUp) {
                newImages.push({ ...img, sortKey: i + 2 });
            } else if (i === changedIdx + 1 && !shiftUp) {
                newImages.push({ ...img, sortKey: i });
            } else {
                newImages.push({ ...img, sortKey: i + 1 });
            }
        });
    } else {
        sortBy(images, (img) => img.sortKey).forEach((img, i) => {
            newImages.push({ ...img, sortKey: i + 1 });
        });
    }
    const updatedImages: WorkspaceImage[] = [];
    const updatedLocalImages: LocalWorkspaceImage[] = [];
    newImages.forEach((img) => {
        if (isLocalWorkspaceImage(img)) updatedLocalImages.push(img);
        else updatedImages.push(img);
    });
    return [updatedImages, updatedLocalImages];
};

const UploadedImage = ({
    image,
    onRemove,
    onShift,
    titleImage,
    children,
}: {
    image: WorkspaceImage;
    onRemove: (id: number | string) => void;
    onShift: (up: boolean) => void;
    titleImage: boolean;
    children: React.ReactNode;
}): React.ReactElement => {
    const { t } = useTranslation();

    const onShiftFileUp: () => void = () => onShift(true);
    const onShiftFileDown: () => void = () => onShift(false);
    const onRemoveImage: () => void = () => onRemove(image.id);

    return (
        <div className={styles.inputWrapper} style={{ order: image.sortKey }}>
            {children}
            <div className={styles.buttonWrapper}>
                <button type="button" className={styles.button} onClick={onShiftFileUp} aria-label="shift image up">
                    <img alt="" src={ShiftUpIcon} />
                </button>
                <button type="button" className={styles.button} onClick={onShiftFileDown} aria-label="shift image down">
                    <img alt="" src={ShiftDownIcon} />
                </button>
                <button
                    type="button"
                    className={`${styles.button} ${styles.binIcon}`}
                    onClick={onRemoveImage}
                    aria-label="Remove image"
                >
                    <img alt="" src={BinIcon} />
                </button>
            </div>
            {titleImage && (
                <div className={styles.titleImageBadge}>
                    <img alt="" src={CheckIcon} className={styles.badgeIcon} />
                    <div>{t('myspace.workspace.imageInput.titleImage')}</div>
                </div>
            )}
        </div>
    );
};

const ImageInput = ({
    images,
    localImages,
    onChange,
}: {
    images: WorkspaceImage[];
    localImages: LocalWorkspaceImage[];
    onChange: (remote: WorkspaceImage[], local: LocalWorkspaceImage[]) => void;
}): React.ReactElement => {
    const [errors, setErrors] = useState(new Set<ValidationResult>());
    const [tooManyImages, setTooManyImages] = useState(false);
    const { t } = useTranslation();

    const addFiles = useCallback(
        (newFiles: File[]) => {
            const newError = new Set<ValidationResult>();

            const additionalFiles = [];
            const currMaxKey = max([...images, ...localImages].map((img) => img.sortKey)) ?? 0;
            for (let i = 0; i < newFiles.length; i += 1) {
                const validation = validateFile(newFiles[i]);
                if (validation === ValidationResult.OK)
                    additionalFiles.push({
                        id: uniqueId('local-image-'),
                        file: newFiles[i],
                        sortKey: currMaxKey + i + 1,
                        thumbnailUrl: '',
                        hiresUrl: '',
                    });
                else newError.add(validation);
            }
            if (images.length + localImages.length + additionalFiles.length > 10) {
                setTooManyImages(true);
                if (images.length + localImages.length < 10) {
                    onChange(images, [
                        ...localImages,
                        ...additionalFiles.slice(0, 10 - images.length - localImages.length),
                    ]);
                }
            } else {
                setTooManyImages(false);
                onChange(images, [...localImages, ...additionalFiles]);
            }
            setErrors(newError);
        },
        [localImages, onChange, images],
    );

    const removeFile = useCallback(
        (id: number | string) => {
            setTooManyImages(false);
            const remoteIdx = findIndex(images, (img) => img.id === id);
            if (remoteIdx !== -1) {
                const [updatedImages, updatedLocalImages] = reSortImages([
                    ...images.slice(0, remoteIdx),
                    ...images.slice(remoteIdx + 1, images.length),
                    ...localImages,
                ]);
                onChange(updatedImages, updatedLocalImages);
            } else {
                const localIdx = findIndex(localImages, (img) => img.id === id);
                if (localIdx !== -1) {
                    const [updatedImages, updatedLocalImages] = reSortImages([
                        ...images,
                        ...localImages.slice(0, localIdx),
                        ...localImages.slice(localIdx + 1, localImages.length),
                    ]);
                    onChange(updatedImages, updatedLocalImages);
                }
            }
        },
        [localImages, onChange, images],
    );

    const shiftFile = useCallback(
        (idx): ((up: boolean) => void) => {
            return (up: boolean): void => {
                const [updatedImages, updatedLocalImages] = reSortImages([...images, ...localImages], idx, up);
                onChange(updatedImages, updatedLocalImages);
            };
        },
        [images, localImages, onChange],
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files != null) {
            addFiles(Array.from(e.target.files));
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        const newFiles = [];
        for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
            if (e.dataTransfer.items[i].kind === 'file') {
                const f = e.dataTransfer.items[i].getAsFile();
                if (f != null) newFiles.push(f);
            }
        }
        if (newFiles.length > 0) addFiles(newFiles);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        // eslint-disable-next-line no-param-reassign
        e.dataTransfer.dropEffect = 'copy';
    };

    const id = uniqueId('image-upload-');
    const sortedImages = sortBy([...images, ...localImages], (img) => img.sortKey);
    return (
        <>
            <div className={styles.wrapper} onDragOver={handleDragOver} onDrop={handleDrop}>
                {sortedImages.map((img, i) => (
                    <UploadedImage
                        image={img}
                        onRemove={removeFile}
                        onShift={shiftFile(i)}
                        titleImage={i === 0}
                        key={img.id}
                    >
                        {isLocalWorkspaceImage(img) ? (
                            <img alt="" src={URL.createObjectURL(img.file)} className={styles.previewImage} />
                        ) : (
                            <img alt="" src={img.thumbnailUrl} className={styles.previewImage} />
                        )}
                    </UploadedImage>
                ))}
                {images.length + localImages.length < 10 && (
                    <div className={styles.inputWrapper} style={{ order: 1000 }}>
                        <label htmlFor={id} className={styles.label}>
                            <input
                                type="file"
                                id={id}
                                className={styles.input}
                                onChange={handleChange}
                                key={id}
                                multiple
                                accept={allowedImageTypes.join(',')}
                            />
                            <img src={MediaIcon} alt="" />
                            <div>
                                <div className={styles.labelText}>{t('actions.input.file.add')}</div>
                                {Array.from(errors.values()).map((e) => (
                                    <div className={styles.errorText} key={e}>
                                        {t(e)}
                                    </div>
                                ))}
                            </div>
                        </label>
                    </div>
                )}
            </div>
            {tooManyImages && (
                <div className={styles.feedback}>
                    <img className={styles.alertIcon} src={alertIcon} alt="" />
                    <h4 className={styles.imageWarning}>{t('myspace.image.warning')}</h4>
                </div>
            )}
        </>
    );
};

export default ImageInput;
