import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import { CreditPaket } from '../../../../types/Credits';
import LoadingIndicator from '../../../LoadingIndicator';
import Select from '../../../templates/Select';
import TextInput from '../../../templates/TextInput';
import DatatransLightbox from './DatatransLightbox';
import * as styles from './Purchase.css';

const API = process.env.REACT_APP_API_URL;

const ImmediatePurchase = (): React.ReactElement => {
    const { t } = useTranslation();
    const [creditPakets, setCreditPakets] = useState<CreditPaket[]>([]);
    const [searchParams] = useSearchParams();
    const [selectedPaket, setSelectedPaket] = useState<CreditPaket>();
    const [amount, setAmount] = useState<number>(1);
    const auth = useAuth();
    const companyIdParam = searchParams.get('companyId');
    const companyId = companyIdParam ? parseInt(companyIdParam, 10) : undefined;

    useEffect(() => {
        const fetchCreditPakets = async (): Promise<void> => {
            try {
                const response = await fetch(`${API}/v1/admin/companies/${companyId}/abo/credits/available-pakets`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch available credit packages');
                }

                const data = await response.json();
                setSelectedPaket(data.aboCreditPakets[0]);
                setCreditPakets(data.aboCreditPakets);
            } catch (error) {
                console.error('Error fetching available credit packages:', error);
            }
        };

        fetchCreditPakets();
    }, [auth.user?.access_token, companyId]);

    const totalCredits = (): number => {
        if (selectedPaket !== undefined && amount !== undefined) {
            return selectedPaket?.includedCredits * amount;
        }
        return 0;
    };

    const total = (): number => {
        if (selectedPaket !== undefined && amount !== undefined) {
            return selectedPaket?.paketPrice * amount;
        }
        return 0;
    };
    return (
        <>
            <div className={styles.paketWrapper}>
                <div>
                    <div className={styles.text({ size: 'm', weight: 'bold' })} style={{ paddingBottom: '0.5rem' }}>
                        {t('purchase.top.up.name')}
                    </div>
                    {creditPakets.length !== 0 ? (
                        <Select
                            options={creditPakets.map((creditPaket) => ({
                                value: creditPaket.paketName,
                                label: creditPaket.paketName,
                            }))}
                            value={selectedPaket?.paketName ?? ''}
                            onChange={(v: string) => {
                                const choosenPaket = creditPakets.find((paket) => {
                                    return paket.paketName === v;
                                });
                                setSelectedPaket(choosenPaket);
                            }}
                            width="355px"
                            border
                        />
                    ) : (
                        <div style={{ width: '355px', marginTop: '1rem' }}>
                            <LoadingIndicator />
                        </div>
                    )}
                </div>
                <div>
                    <div className={styles.text({ size: 'm', weight: 'bold' })} style={{ paddingBottom: '0.5rem' }}>
                        {t('amount.title')}
                    </div>
                    <TextInput
                        inputHeight="2.6rem"
                        value={amount}
                        required={false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const nr = parseInt(e.target.value, 10);
                            if (Number.isNaN(nr)) {
                                setAmount(0);
                            } else {
                                setAmount(nr);
                            }
                        }}
                    />
                </div>
            </div>
            <div className={styles.paketWrapper}>
                <div>
                    <div className={styles.text({ size: 'm' })}>{t('summary.title')}</div>
                    <div style={{ marginTop: '14px', marginBottom: '5px' }} className={styles.flex}>
                        <div
                            style={{ width: '150px' }}
                            className={styles.text({ size: 's', weight: 'normal' })}
                        >{`${amount}x ${selectedPaket?.paketName}`}</div>
                        <div
                            style={{ width: '150px' }}
                            className={styles.text({ size: 's', weight: 'normal' })}
                        >{`${totalCredits()} ${t('booking.credits')}`}</div>
                    </div>
                    <div className={styles.flex}>
                        <div
                            className={styles.text({ size: 's', weight: 'bold' })}
                            style={{ paddingBottom: '0.5rem', width: '150px' }}
                        >
                            {t('total.title')}
                        </div>
                        <div
                            className={styles.text({ size: 's', weight: 'bold' })}
                            style={{ paddingBottom: '0.5rem', width: '150px' }}
                        >
                            {`${t('chf.title')} ${total()}.–`}
                        </div>
                    </div>
                    <DatatransLightbox
                        companyId={companyId}
                        costs={total()}
                        amount={amount}
                        credits={totalCredits()}
                        selectedPaket={selectedPaket}
                    />
                </div>
            </div>
        </>
    );
};

export default ImmediatePurchase;
