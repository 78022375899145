import React from 'react';
import * as styles from './SortArrow.css';

const SortArrows = ({ arrowOption }: { arrowOption: string | boolean }): React.ReactElement => {
    return (
        <>
            {arrowOption === false && (
                <div className={styles.arrow} role="button" tabIndex={0} aria-label="sorting disabled">
                    <span className={styles.upArrow.disabled} />
                    <span className={styles.downArrow.disabled} />
                </div>
            )}
            {arrowOption === 'asc' && (
                <div className={styles.arrow} role="button" tabIndex={0} aria-label="ascending sort">
                    <span className={styles.upArrow.active} />
                    <span className={styles.downArrow.transparent} />
                </div>
            )}

            {arrowOption === 'desc' && (
                <div className={styles.arrow} role="button" tabIndex={0} aria-label="descending sort">
                    <span className={styles.upArrow.transparent} />
                    <span className={styles.downArrow.active} />
                </div>
            )}
        </>
    );
};

export default SortArrows;
