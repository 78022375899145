import React, { useCallback, useEffect, useReducer, useState } from 'react';
import filter from 'lodash/filter';
import { useTranslation } from '@ubique-innovation/react-translations';

import formReducer, { FormReducerArgs } from '../../../reducers/formReducer';
import ResourcesTab from './ResourcesTab';
import FormControls from '../../templates/FormControls';
import { LocalWorkspaceImage, Workspace } from '../../../types/Workspace';

const ResourcesForm = ({
    workspace,
    onSave,
    setDirty,
    savingError,
}: {
    workspace: Workspace;
    onSave: (w: Workspace, localImages?: LocalWorkspaceImage[]) => void;
    setDirty: (d: boolean) => void;
    savingError: string | undefined;
}): React.ReactElement => {
    const [valid, setValid] = useState(false);
    const [formState, dispatchFormState] = useReducer<
        (state: Workspace, updateArgs: FormReducerArgs<Workspace>) => Workspace
    >(formReducer, workspace);
    const { t } = useTranslation();
    let v = false;

    const validateForm = (fState: Workspace): boolean => {
        const productNoPrice = filter(fState.products, (l) => l.price === 0);
        if (productNoPrice.length > 0) {
            v = false;
        } else {
            v = true;
        }
        return v;
    };

    const resetForm = useCallback(() => {
        dispatchFormState(workspace);
        setDirty(false);
    }, [workspace, setDirty]);

    useEffect(() => {
        resetForm();
    }, [workspace, resetForm]);

    useEffect(() => {
        setValid(validateForm(formState));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    const handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (!valid) {
            alert(t('messages.error.invalidForm'));
            return;
        }
        onSave(formState);
    };

    return (
        <>
            <ResourcesTab
                formState={formState}
                dispatch={dispatchFormState}
                onSubmit={handleSubmit}
                setDirty={setDirty}
            />
            <FormControls id="workspace" onReset={resetForm} formValid={valid} errorMessage={savingError} />
        </>
    );
};

export default ResourcesForm;
