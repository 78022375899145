import { ProductType } from './Workspace';

export enum BookingState {
    REQUESTED = 'REQUESTED',
    APPROVED = 'APPROVED',
    FINISHED = 'FINISHED',
    CHECKED_IN = 'CHECKED_IN',
    DECLINED = 'DECLINED',
    CANCELLED_REQUEST = 'CANCELLED_REQUEST',
    CANCELLED_BOOKING = 'CANCELLED_BOOKING',
    ONBOARDED = 'ONBOARDED',
}

export interface Booking {
    bookingId: number;
    bookingCode: string;
    name: string;
    email: string;
    productType: ProductType;
    bookingState: BookingState;
    bookingOption: BookingOption;
    startAt: number;
    endAt: number;
    workspaceCode: string;
    workspaceId: number;
}

export enum BookingOption {
    DAYPASS = 'DAYPASS',
    MORNING_PASS = 'MORNING_PASS',
    AFTERNOON_PASS = 'AFTERNOON_PASS',
    MULTIDAY = 'MULTIDAY',
}

export const bookingOptionOrder = {
    [BookingOption.DAYPASS]: 0,
    [BookingOption.MORNING_PASS]: 1,
    [BookingOption.AFTERNOON_PASS]: 2,
    [BookingOption.MULTIDAY]: 3,
    null: 4,
} as const;

export interface CompanyBooking {
    bookingId: number;
    bookingCode: string;
    name: string;
    email: string;
    productType: ProductType;
    bookingState: BookingState;
    bookingOption: BookingOption;
    startAt: number;
    endAt: number;
    city: string;
    credits: number;
    category: string;
    workspaceCode: string;
    workspaceTitle: string;
    workspaceId: number;
}

export interface TopUpPurchase {
    date: number;
    topUp: string;
    amount: number;
    credits: number;
    price: number;
    purchaseMethod: string;
}
