import React, { useCallback, useReducer } from 'react';

import formReducer, { FormReducerArgs } from '../../reducers/formReducer';
import { Contact, InvoiceRecipient } from '../../types/Workspace';
import CompanyAccountTab from '../../components/company/companyAccount/CompanyAccountTab';

const CompanyAccount = ({
    invoiceRecipient,
    contact,
    onInvoiceRecipientSave,
    onContactSave,
    savingError,
    incomplete,
}: {
    invoiceRecipient: InvoiceRecipient;
    contact: Contact;
    onInvoiceRecipientSave: (w: InvoiceRecipient) => void;
    onContactSave: (w: Contact) => void;
    savingError: string | undefined;
    incomplete: boolean;
}): React.ReactElement => {
    const [formInvoiceRecipient, dispatchFormInvoiceRecipient] = useReducer<
        (state: InvoiceRecipient, updateArgs: FormReducerArgs<InvoiceRecipient>) => InvoiceRecipient
    >(formReducer, invoiceRecipient);

    const [formContact, dispatchFormContact] = useReducer<
        (state: Contact, updateArgs: FormReducerArgs<Contact>) => Contact
    >(formReducer, contact);

    const resetInvoiceRecipient = useCallback(() => {
        dispatchFormInvoiceRecipient(invoiceRecipient);
    }, [invoiceRecipient]);

    const resetContact = useCallback(() => {
        dispatchFormContact(contact);
    }, [contact]);

    const handleInvoiceRecipientSubmit = (): void => {
        onInvoiceRecipientSave(formInvoiceRecipient);
    };

    const handleContactSubmit = (): void => {
        onContactSave(formContact);
    };

    return (
        <>
            <CompanyAccountTab
                formInvoiceRecipient={formInvoiceRecipient}
                formContact={formContact}
                dispatchFormInvoiceRecipient={dispatchFormInvoiceRecipient}
                dispatchFormContact={dispatchFormContact}
                onInvoiceRecipientSubmit={handleInvoiceRecipientSubmit}
                onContactSubmit={handleContactSubmit}
                resetInvoiceRecipient={resetInvoiceRecipient}
                resetContact={resetContact}
                savingError={savingError}
                incomplete={incomplete}
            />
        </>
    );
};

export default CompanyAccount;
