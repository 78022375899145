import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

const getJwtValue: (base64Url: string, jwtKey: string) => string[] | string | undefined = (base64Url, jwtKey) => {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(''),
    );
    return JSON.parse(jsonPayload)[jwtKey];
};

const useJwtValue = <T>(jwtKey: string, valueMapper: (v: string) => T): T[] | undefined => {
    const [values, setValues] = useState<T[]>();
    const auth = useAuth();
    useEffect(() => {
        if (auth.user) {
            const base64Url = auth.user.access_token.split('.')[1];
            const jwtValue = getJwtValue(base64Url, jwtKey);
            if (jwtValue != null) {
                if (Array.isArray(jwtValue)) {
                    setValues(jwtValue.map(valueMapper));
                } else {
                    setValues([valueMapper(jwtValue)]);
                }
            } else {
                setValues([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user, auth.user?.access_token, jwtKey, valueMapper]);
    return values;
};

export default useJwtValue;
