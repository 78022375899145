import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';

const API = process.env.REACT_APP_API_URL;

const RedeemPayment = (): React.ReactElement | null => {
    const auth = useAuth();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.has('datatransTrxState') && searchParams.has('datatransTrxId')) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const datatransTrxState = searchParams.get('datatransTrxState')!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const datatransTrxId = searchParams.get('datatransTrxId')!;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const companyId = parseInt(searchParams.get('companyId')!, 10);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const credits = parseInt(searchParams.get('credits')!, 10);

            if (datatransTrxState === 'success') {
                fetch(`${API}/v1/admin/companies/${companyId}/abo/credits/topup-redeem/${datatransTrxId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                })
                    .then((response) => response.json())
                    .then(() => {
                        navigate({
                            pathname: `/company/credits/purchase`,
                            search: `?success=true&credits=${credits}`,
                        });
                    })
                    .catch((error) => {
                        console.error('Purchase failed, error', error);
                        navigate({
                            pathname: `/company/credits/purchase`,
                            search: '?success=false',
                        });
                    });
            } else {
                navigate({
                    pathname: `/company/credits/purchase`,
                    search: '?success=false',
                });
            }
        }
    });

    return null;
};

export default RedeemPayment;
