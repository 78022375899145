import React, { useCallback } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';

import { FormReducerDispatch } from '../../../reducers/formReducer';
import { AddressCountryShort, Beneficiary, BookingsInfo, Contact, Locale } from '../../../types/Workspace';
import * as styles from './AccountTab.css';
import EditBlock, { Formats } from '../../templates/EditBlock';
import ErrorMessage from '../../templates/ErrorMessage';

const AccountTab = ({
    formBeneficiary,
    formContact,
    formBooking,

    dispatchFormBeneficiary,
    dispatchFormContact,
    dispatchFormBooking,

    onBeneficiarySubmit,
    onContactSubmit,
    onBookingSubmit,

    resetBeneficiary,
    resetContact,
    resetBooking,

    savingError,
    incomplete,
}: {
    formBeneficiary: Beneficiary;
    formContact: Contact;
    formBooking: BookingsInfo;

    dispatchFormBeneficiary: FormReducerDispatch<Beneficiary>;
    dispatchFormContact: FormReducerDispatch<Contact>;
    dispatchFormBooking: FormReducerDispatch<BookingsInfo>;

    onBeneficiarySubmit: React.FormEventHandler;
    onContactSubmit: React.FormEventHandler;
    onBookingSubmit: React.FormEventHandler;

    resetBeneficiary: () => void;
    resetContact: () => void;
    resetBooking: () => void;

    savingError: string | undefined;
    incomplete: boolean;
}): React.ReactElement => {
    const { t } = useTranslation();

    const updateForm = useCallback(
        (
            { target: { value, name, type, checked } }: React.ChangeEvent<HTMLInputElement>,
            dispatchForm: FormReducerDispatch<Beneficiary | Contact | BookingsInfo>,
        ) => {
            const updatePath = name.split('.');
            if (type === 'checkbox') {
                dispatchForm({
                    _path: updatePath,
                    _value: checked,
                });
                return;
            }
            if (updatePath.length === 1) {
                const [key] = updatePath;
                dispatchForm({
                    [key]: value,
                });
            } else {
                dispatchForm({
                    _path: updatePath,
                    _value: value,
                });
            }
        },
        [],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.pageTitle}>{t('navigation.settings')}</div>
            {incomplete && <ErrorMessage text={t('contactdetails.error-message')} width={55} />}
            {savingError && <div className={styles.errorMessage}>{savingError}</div>}
            <form id="contact" className={styles.inputForm} onSubmit={onContactSubmit}>
                <EditBlock
                    id="contact"
                    blockTitle={t('contact.subtitle')}
                    blockSets={[
                        {
                            name: 'firstName',
                            label: t('settings.payment.information.firstName'),
                            value: formContact.firstName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => updateForm(e, dispatchFormContact),
                        },
                        {
                            name: 'lastName',
                            label: t('settings.payment.information.lastName'),
                            value: formContact.lastName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => updateForm(e, dispatchFormContact),
                        },
                        {
                            name: 'mail',
                            label: t('settings.payment.information.mail'),
                            value: formContact.mail,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => updateForm(e, dispatchFormContact),
                        },
                        {
                            name: 'correspondenceLanguage',
                            label: t('settings.payment.information.correspondence'),
                            value: formContact.correspondenceLanguage,
                            required: true,
                            format: Formats.DROPDOWN,
                            options: [
                                {
                                    value: Locale.DE,
                                    label: t('flesk.language.de'),
                                },
                                {
                                    value: Locale.FR,
                                    label: t('flesk.language.fr'),
                                },
                                {
                                    value: Locale.EN,
                                    label: t('flesk.language.en'),
                                },
                            ],
                            onChange: (v: Locale) => {
                                dispatchFormContact({ correspondenceLanguage: v });
                            },
                        },
                    ]}
                    reset={resetContact}
                />
            </form>

            <form id="bookings" className={styles.inputForm} onSubmit={onBookingSubmit}>
                <EditBlock
                    id="bookings"
                    blockTitle={t('navigation.bookings')}
                    blockDesc={t('account.bookingmail.desc')}
                    blockSets={[
                        {
                            name: 'bookingMail',
                            label: t('settings.payment.information.mail'),
                            value: formBooking.bookingMail,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => updateForm(e, dispatchFormBooking),
                        },
                    ]}
                    reset={resetBooking}
                />
            </form>

            <form id="beneficiary" className={styles.inputForm} onSubmit={onBeneficiarySubmit}>
                <EditBlock
                    id="beneficiary"
                    blockTitle={t('account.invoiceAddress')}
                    blockSets={[
                        {
                            name: 'beneficiaryBusinessName',
                            label: t('settings.payment.information.companyname'),
                            value: formBeneficiary.beneficiaryBusinessName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'beneficiaryPersonFirstName',
                            label: t('settings.payment.information.firstName'),
                            value: formBeneficiary.beneficiaryPersonFirstName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'beneficiaryPersonLastName',
                            label: t('settings.payment.information.lastName'),
                            value: formBeneficiary.beneficiaryPersonLastName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'beneficiaryMail',
                            label: t('settings.payment.information.mail'),
                            value: formBeneficiary.beneficiaryMail,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'street',
                            label: t('settings.payment.information.contactadress'),
                            value: formBeneficiary.street,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'postCode',
                            label: t('settings.payment.information.contactpostcode'),
                            value: formBeneficiary.postCode,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'city',
                            label: t('settings.payment.information.contactcity'),
                            value: formBeneficiary.city,
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'country',
                            label: t('settings.payment.information.contactcountry'),
                            value: formBeneficiary.country,
                            required: true,
                            format: Formats.DROPDOWN,
                            options: Object.keys(AddressCountryShort).map((country) => ({
                                value: country,
                                label: country,
                            })),
                            onChange: (country: AddressCountryShort) => {
                                dispatchFormBeneficiary({ country });
                            },
                        },
                        {
                            name: 'beneficiaryIban',
                            label: t('settings.payment.information.iban'),
                            value: formBeneficiary.beneficiaryIban || '',
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                        },
                        {
                            name: 'mwstPflichtig',
                            label: t('settings.payment.information.mwstpflichtig'),
                            value: formBeneficiary.mwstPflichtig ? t('settings.yes') : t('settings.no'),
                            required: true,
                            format: Formats.DROPDOWN,
                            options: [
                                {
                                    value: t('settings.yes'),
                                    label: t('settings.yes'),
                                },
                                {
                                    value: t('settings.no'),
                                    label: t('settings.no'),
                                },
                            ],
                            onChange: (e: string) =>
                                e === t('settings.yes')
                                    ? dispatchFormBeneficiary({ mwstPflichtig: true })
                                    : dispatchFormBeneficiary({ mwstPflichtig: false }),
                        },
                        {
                            name: 'mwstNumber',
                            label: t('settings.mwst.label'),
                            value: formBeneficiary.mwstNumber ? formBeneficiary.mwstNumber : '',
                            required: true,
                            format: Formats.INPUT,
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                                updateForm(e, dispatchFormBeneficiary),
                            placeholder:
                                formBeneficiary.country === AddressCountryShort.LI
                                    ? ''
                                    : t('settings.mwst.placeholder'),
                            hide: !formBeneficiary.mwstPflichtig,
                        },
                    ]}
                    reset={resetBeneficiary}
                />
            </form>
        </div>
    );
};

export default AccountTab;
