import React, { useCallback } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { useTranslation } from '@ubique-innovation/react-translations';

import * as styles from './WorkspaceTab.css';
import TextInput from '../../templates/TextInput';
import AreaText from '../../templates/AreaText';
import SectionTitle from '../../templates/SectionTitle';
import ImageInput from '../../templates/ImageInput';
import { Locale, LocalWorkspaceImage, TimeSlots, Workspace, WorkspaceImage } from '../../../types/Workspace';
import OpeningHours from '../../templates/OpeningHours';
import Select from '../../templates/Select';
import LanguageCheckbox from '../../templates/LanguageCheckbox';
import { FormReducerDispatch } from '../../../reducers/formReducer';

const WorkspaceTab = ({
    formState,
    dispatch,
    localImages,
    setLocalImages,
    secondLanguages,
    setSecondLanguages,
    onSubmit,
    setDirty,
    openingHours,
    setOpeningHours,
}: {
    formState: Workspace;
    dispatch: FormReducerDispatch<Workspace>;
    localImages: LocalWorkspaceImage[];
    setLocalImages: (i: LocalWorkspaceImage[]) => void;
    secondLanguages: Locale[];
    setSecondLanguages: (l: Locale[]) => void;
    onSubmit: React.FormEventHandler;
    setDirty: (d: boolean) => void;
    openingHours: TimeSlots;
    setOpeningHours: (d: TimeSlots) => void;
}): React.ReactElement => {
    const { t } = useTranslation();

    const updateForm = useCallback(
        ({ target: { value, name, type, checked } }: React.ChangeEvent<HTMLInputElement>) => {
            const updatePath = name.split('.');
            // if the input is a checkbox then use callback function to update
            // the toggle state based on previous state
            if (type === 'checkbox') {
                dispatch({
                    _path: updatePath,
                    _value: checked,
                });

                return;
            }

            // if we have to update the root level nodes in the form
            if (updatePath.length === 1) {
                const [key] = updatePath;

                dispatch({
                    [key]: value,
                });
            } else {
                // if we have to update nested nodes in the form object
                // use _path and _value to update them.
                dispatch({
                    _path: updatePath,
                    _value: value,
                });
            }
            setDirty(true);
        },
        [dispatch, setDirty],
    );

    const getLocalizedLabel = (language: string, option: string): string => {
        return t(`myspace.workspace.${option}.${language}`);
    };

    const getLocalizedValue = (
        language: Locale,
        option: 'subtitle' | 'teaser' | 'checkedInInfo' | 'entryInfo',
    ): string => {
        const localization = formState.localizations[language];
        if (localization != null) {
            const str = localization[option];
            if (str != null) return str;
        }
        return '';
    };

    const handleSecondLanguageChange = (language: Locale, checked: boolean): void => {
        if (formState.localizations[language] == null) {
            dispatch((draft) => {
                draft.localizations[language] = {
                    subtitle: '',
                    teaser: '',
                    entryInfo: '',
                    checkedInInfo: '',
                };
            });
            setDirty(true);
        }
        if (checked) {
            if (
                !includes(
                    secondLanguages.map((l) => l),
                    language,
                )
            ) {
                const l = [...secondLanguages];
                l.push(language);
                setSecondLanguages(l);
            }
        } else {
            const newLang = filter(secondLanguages, (l) => l !== language);
            setSecondLanguages(newLang);
        }
    };

    const isLanguageChecked = (type: Locale): boolean => {
        return filter(secondLanguages, (e) => e === type).length > 0;
    };

    return (
        <div className={styles.wrapper}>
            <form id="workspace" className={styles.inputForm} onSubmit={onSubmit}>
                <div className={styles.generalTitle}>{t('flesk.title.general-informationen')}</div>

                <SectionTitle title={t('myspace.workspace.title.spacename')} required />
                <TextInput value={formState.title} name="title" onChange={updateForm} required />

                <SectionTitle title={t('workspaces.category.code')} />
                <div className={styles.description}>{formState.workspaceCode}</div>

                <SectionTitle title={t('myspace.workspace.images.title')} />
                <ImageInput
                    images={formState.imageIdSortKeys}
                    localImages={localImages}
                    onChange={(remoteImages: WorkspaceImage[], localFiles: LocalWorkspaceImage[]) => {
                        dispatch({ imageIdSortKeys: remoteImages });
                        setLocalImages(localFiles);
                        setDirty(true);
                    }}
                />

                <SectionTitle title={t('myspace.workspace.title.description')} />
                <Select
                    label={`${t('flesk.language.primary')} *`}
                    options={[
                        {
                            value: Locale.DE,
                            label: t('flesk.language.de'),
                        },
                        {
                            value: Locale.FR,
                            label: t('flesk.language.fr'),
                        },
                        {
                            value: Locale.EN,
                            label: t('flesk.language.en'),
                        },
                    ]}
                    value={formState.primaryLang}
                    onChange={(v: Locale) => {
                        secondLanguages.push(formState.primaryLang);
                        dispatch({ primaryLang: v });
                        handleSecondLanguageChange(v, false);
                        setDirty(true);
                    }}
                    desc={t('flesk.primarylanguage.explanation')}
                    width="10rem"
                    border
                />
                <div className={styles.secondLangTitle}>{t('flesk.language.secondary')}</div>
                <div className={styles.langCheckboxes}>
                    {formState.primaryLang !== Locale.DE && (
                        <LanguageCheckbox
                            label={t('flesk.language.de')}
                            checked={isLanguageChecked(Locale.DE)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleSecondLanguageChange(Locale.DE, e.target.checked);
                            }}
                        />
                    )}
                    {formState.primaryLang !== Locale.FR && (
                        <LanguageCheckbox
                            label={t('flesk.language.fr')}
                            checked={isLanguageChecked(Locale.FR)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleSecondLanguageChange(Locale.FR, e.target.checked);
                            }}
                        />
                    )}
                    {formState.primaryLang !== Locale.EN && (
                        <LanguageCheckbox
                            label={t('flesk.language.en')}
                            checked={isLanguageChecked(Locale.EN)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleSecondLanguageChange(Locale.EN, e.target.checked);
                            }}
                        />
                    )}
                </div>
                <div className={styles.subtitleTeaser}>
                    <section className={styles.inputSection}>
                        <TextInput
                            label={getLocalizedLabel(formState.primaryLang, 'subtitle')}
                            value={getLocalizedValue(formState.primaryLang, 'subtitle')}
                            name={`localizations.${formState.primaryLang}.subtitle`}
                            onChange={updateForm}
                            required
                        />
                        <AreaText
                            label={getLocalizedLabel(formState.primaryLang, 'teaser')}
                            description={t(`myspace.workspace.teaser.description`)}
                            value={getLocalizedValue(formState.primaryLang, 'teaser')}
                            name={`localizations.${formState.primaryLang}.teaser`}
                            onChange={updateForm}
                            required
                        />
                    </section>
                    {secondLanguages.map((secondLang) => (
                        <React.Fragment key={`${secondLang}-lang`}>
                            <section className={styles.inputSection}>
                                <TextInput
                                    label={getLocalizedLabel(secondLang, 'subtitle')}
                                    value={getLocalizedValue(secondLang, 'subtitle')}
                                    name={`localizations.${secondLang}.subtitle`}
                                    onChange={updateForm}
                                    required
                                />
                                <AreaText
                                    label={getLocalizedLabel(secondLang, 'teaser')}
                                    description={t(`myspace.workspace.teaser.description`)}
                                    value={getLocalizedValue(secondLang, 'teaser')}
                                    name={`localizations.${secondLang}.teaser`}
                                    onChange={updateForm}
                                    required
                                />
                            </section>
                        </React.Fragment>
                    ))}
                </div>

                <SectionTitle title={t('myspace.workspace.title.adress')} />
                <div className={styles.description}>{formState.address}</div>
                <OpeningHours
                    workspace={openingHours}
                    onChange={(days: TimeSlots) => {
                        setOpeningHours(days);
                        setDirty(true);
                    }}
                />
                <div className={styles.onboardingTitle}>{t('flesk.title.onboarding-informationen')}</div>
                <h2 className={styles.subTitle}>{t('myspace.onboadinginfo.title.entryinfo')}</h2>

                <div className={styles.subtitleTeaser}>
                    <section className={styles.inputSection}>
                        <SectionTitle title={getLocalizedLabel(formState.primaryLang, 'entryInfo')} required />
                        <AreaText
                            value={getLocalizedValue(formState.primaryLang, 'entryInfo')}
                            description={t(`myspace.workspace.entryInfo.description`)}
                            name={`localizations.${formState.primaryLang}.entryInfo`}
                            onChange={updateForm}
                            required
                        />
                    </section>
                    {secondLanguages.map((secondLang) => (
                        <React.Fragment key={secondLang}>
                            <section className={styles.inputSection}>
                                <SectionTitle title={getLocalizedLabel(secondLang, 'entryInfo')} required />
                                <AreaText
                                    value={getLocalizedValue(secondLang, 'entryInfo')}
                                    description={t(`myspace.workspace.entryInfo.description`)}
                                    name={`localizations.${secondLang}.entryInfo`}
                                    onChange={updateForm}
                                    required
                                />
                            </section>
                        </React.Fragment>
                    ))}{' '}
                </div>
                <h2 className={styles.subTitle}>{t('myspace.onboardinginfo.title.wifiinfo')}</h2>

                <SectionTitle title={t('myspace.onboardinginfo.wifi.title')} />
                <TextInput
                    label={t('myspace.onboardinginfo.wifi.name')}
                    value={formState.wlanSsid}
                    name="wlanSsid"
                    onChange={updateForm}
                    required={false}
                />
                <TextInput
                    label={t('myspace.onboardinginfo.wifi.password')}
                    value={formState.wlanPassword}
                    name="wlanPassword"
                    onChange={updateForm}
                    required={false}
                />

                <div className={styles.subtitleTeaser}>
                    <section className={styles.inputSection}>
                        <SectionTitle title={getLocalizedLabel(formState.primaryLang, 'checkedInInfo')} required />
                        <AreaText
                            value={getLocalizedValue(formState.primaryLang, 'checkedInInfo')}
                            description={t(`myspace.workspace.checkin.description`)}
                            name={`localizations.${formState.primaryLang}.checkedInInfo`}
                            onChange={updateForm}
                            required
                        />
                    </section>
                    {secondLanguages.map((secondLang) => (
                        <React.Fragment key={secondLang}>
                            <section className={styles.inputSection}>
                                <SectionTitle title={getLocalizedLabel(secondLang, 'checkedInInfo')} required />
                                <AreaText
                                    value={getLocalizedValue(secondLang, 'checkedInInfo')}
                                    description={t(`myspace.workspace.checkin.description`)}
                                    name={`localizations.${secondLang}.checkedInInfo`}
                                    onChange={updateForm}
                                    required
                                />
                            </section>
                        </React.Fragment>
                    ))}
                </div>
            </form>
        </div>
    );
};

export default WorkspaceTab;
