import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from '@ubique-innovation/react-translations';

import * as styles from './User.css';
import LoadingIndicator from '../../components/LoadingIndicator';
import UserTab from '../../components/company/user/UserTab';
import { CompanyUser } from '../../types/Company';

const User = ({ companyId }: { companyId: number | undefined }): React.ReactElement => {
    const [users, setUsers] = useState<CompanyUser[]>([]);
    const [loading, setLoading] = useState(true);
    const API = process.env.REACT_APP_API_URL;
    const auth = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        if (companyId == null) {
            // TODO handle this
            // console.error('this user has no workspace');
            return;
        }
        fetch(`${API}/v1/admin/companies/${companyId}/users`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                // if (response.status === (403 || 401)) {
                //     auth.signoutRedirect();
                // }
                return Promise.reject();
            })
            .then(
                (data) => {
                    setUsers(data.users);
                    setLoading(false);
                },
                () => console.error("couldn't fetch record"), // TODO error handling
            );
    }, [API, auth, auth.user?.access_token, companyId]);

    return (
        <>
            <div className={styles.contentWrapper}>
                {loading || users == null ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <div className={styles.generalTitle}>{t('company.nav.user')}</div>
                        <UserTab users={users} />
                    </>
                )}
            </div>
        </>
    );
};

export default User;
