import React from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from '@ubique-innovation/react-translations';

import { CompanyUser } from '../../../types/Company';
import Table from '../../templates/Table';

const UserTab = ({ users }: { users: CompanyUser[] }): React.ReactElement => {
    const { t } = useTranslation();

    const data: CompanyUser[] = users;

    const columns = React.useMemo<ColumnDef<CompanyUser>[]>(
        () => [
            {
                header: t('bookings.category.name'),
                accessorKey: 'name',
                cell: (info) => info.getValue(),
            },
            {
                header: t('bookings.category.email'),
                accessorKey: 'email',
                cell: (info) => info.getValue(),
            },
        ],
        [t],
    );

    return (
        <>
            <Table
                sort={t('bookings.category.name')}
                {...{
                    data,
                    columns,
                }}
            />
            <hr />
        </>
    );
};

export default UserTab;
