import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SuccessCheck from '../../../../images/company/success_check.svg';
import { Pathname } from '../../../../types/Pathname';
import ErrorMessage from '../../../templates/ErrorMessage';
import ImmediatePurchase from './ImmediatePurchase';
import InvoicePurchase from './InvoicePurchase';
import * as styles from './Purchase.css';
import PurchaseOptions from './PurchaseOptions';

const SuccessBox = (): React.ReactElement => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const credits = parseInt(searchParams.get('credits')!, 10);

    return (
        <>
            <div className={styles.successWrapper}>
                <img src={SuccessCheck} alt="success-box" />
                <div className={styles.successTextWrapper}>
                    <div>{t('purchase.payment.successfull')}</div>
                    <div>{`+${credits} ${t('purchase.payment.info.text')}`}</div>
                </div>
            </div>
            <div>
                <button
                    className={styles.blueBtn}
                    type="button"
                    onClick={() => navigate(`${Pathname.SUBSCRIPTION}${Pathname.CREDITS}`)}
                >
                    {t('purchase.btn.overview')}
                </button>
            </div>
        </>
    );
};
const Purchase = (): React.ReactElement => {
    const { t } = useTranslation();
    const [immediatePurchase, setImmediatePurchase] = useState<boolean>(true);
    const [successfull, setSuccessFull] = useState<boolean | undefined>(undefined);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const success = searchParams.get('success');
        if (success === 'true') {
            setSuccessFull(true);
        } else if (success === 'false') {
            setSuccessFull(false);
        } else {
            setSuccessFull(undefined);
        }
    }, [searchParams]);

    return (
        <div className={styles.contentWrapper}>
            <PurchaseOptions immediatePurchase={immediatePurchase} setImmediatePurchase={setImmediatePurchase} />
            {immediatePurchase ? (
                <>
                    {successfull && <SuccessBox />}
                    {successfull === false && (
                        <ErrorMessage
                            text={`${t('purchase.payment.failed')} `}
                            textThin={t('try.again.btn')}
                            width={50}
                        />
                    )}
                    {!successfull && <ImmediatePurchase />}
                </>
            ) : (
                <div className={styles.iFrame}>
                    <InvoicePurchase />
                </div>
            )}
        </div>
    );
};
export default Purchase;
