import { useTranslation } from '@ubique-innovation/react-translations';
import React from 'react';
import * as styles from './Purchase.css';

const PurchaseOptions = ({
    immediatePurchase,
    setImmediatePurchase,
}: {
    immediatePurchase: boolean;
    setImmediatePurchase: (d: boolean) => void;
}): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.text({ weight: 'bold' })} style={{ paddingBottom: '0.5rem' }}>
                {t('purchase.buy.paket.btn')}
            </div>
            <div className={styles.variantBtns}>
                <button
                    onClick={() => setImmediatePurchase(true)}
                    className={immediatePurchase ? styles.variantBtn.active : styles.variantBtn.standard}
                    type="button"
                >
                    {t('purchase.immediate.title')}
                </button>
                <button
                    onClick={() => setImmediatePurchase(false)}
                    className={!immediatePurchase ? styles.variantBtn.active : styles.variantBtn.standard}
                    type="button"
                >
                    {t('purchase.invoice.title')}
                </button>
            </div>
        </>
    );
};

export default PurchaseOptions;
