import React from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';

import * as styles from './FormControls.css';
import FleskButton from './FleskButton';

const FormControls = ({
    onReset,
    formValid,
    id,
    errorMessage,
}: {
    onReset: () => void;
    formValid: boolean;
    id?: string;
    errorMessage?: string;
}): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className={styles.wrapper}>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
            <FleskButton label={t('flesk.btn.cancel')} darkBtn={false} onClick={onReset} />
            <FleskButton
                label={t('flesk.btn.save')}
                darkBtn
                id={id}
                submit
                disabled={!formValid}
                onClick={formValid ? undefined : () => alert(t('messages.error.invalidForm'))}
            />
        </div>
    );
};
FormControls.defaultProps = {
    id: '',
    errorMessage: undefined,
};

export default FormControls;
