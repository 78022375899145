import React from 'react';
import * as styles from './SectionTitle.css';

const SectionTitle = ({ title, required }: { title: string; required?: boolean }): React.ReactElement => {
    return (
        <div className={styles.title}>
            {title}
            {required ? ' *' : ''}
        </div>
    );
};
SectionTitle.defaultProps = { required: false };

export default SectionTitle;
