import React from 'react';
import * as styles from './AreaText.css';

const AreaText = ({
    label,
    description,
    value,
    onChange,
    required,
    name,
}: {
    label?: string;
    description?: string;
    value?: string;
    required: boolean;
    onChange: React.ChangeEventHandler;
    name?: string;
}): React.ReactElement => {
    return (
        <>
            {label != null && (
                <label htmlFor="input" className={styles.label}>
                    {label} {required && label !== '' ? ' *' : ''}
                </label>
            )}{' '}
            {description != null && (
                <label htmlFor="input" className={styles.description}>
                    {description}
                </label>
            )}
            <textarea
                value={value}
                onChange={onChange}
                className={required && value === '' ? styles.inputFieldArea.required : styles.inputFieldArea.standard}
                name={name}
                maxLength={500}
            />
            <p className={styles.countCharacters}>{value?.length}/500</p>
        </>
    );
};
AreaText.defaultProps = {
    label: '',
    description: '',
    value: '',
    name: undefined,
};

export default AreaText;
