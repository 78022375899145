import React, { useCallback } from 'react';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { useTranslation } from '@ubique-innovation/react-translations';

import * as styles from './ResourcesTab.css';
import { ProductType, Workspace } from '../../../types/Workspace';
import SingleProduct from '../../templates/SingleProduct';
import { FormReducerDispatch } from '../../../reducers/formReducer';

const ResourcesTab = ({
    formState,
    dispatch,
    onSubmit,
    setDirty,
}: {
    formState: Workspace;
    dispatch: FormReducerDispatch<Workspace>;
    onSubmit: React.FormEventHandler;
    setDirty: (d: boolean) => void;
}): React.ReactElement => {
    const { t } = useTranslation();

    const handleChange = useCallback(
        (productType: ProductType, checked: boolean): void => {
            if (checked) {
                if (productType === ProductType.FLESK_DESK) {
                    dispatch((draft) => {
                        draft.products.push({
                            type: productType,
                            price: 0,
                        });
                        draft.products.push({
                            type: ProductType.FLESK_DESK_DROP_IN,
                            price: 0,
                        });
                        draft.deskHalfDayPassesSupported = true;
                    });
                } else if (
                    !includes(
                        formState.products.map((p) => p.type),
                        productType,
                    )
                ) {
                    dispatch((draft) => {
                        draft.products.push({
                            type: productType,
                            price: 0,
                        });
                    });
                }
            } else if (!checked && productType === ProductType.FLESK_DESK) {
                dispatch((draft) => {
                    draft.products = filter(
                        draft.products,
                        (p) => p.type !== ProductType.FLESK_DESK_DROP_IN && p.type !== productType,
                    );
                });
            } else {
                dispatch((draft) => {
                    draft.products = filter(draft.products, (p) => p.type !== productType);
                });
            }
            setDirty(true);
        },
        [dispatch, setDirty, formState.products],
    );

    const priceChange = useCallback(
        (type: ProductType, price: string): void => {
            const productPrice = parseInt(price, 10);

            const regex = /^[a-zA-Z]+/g;
            if (regex.test(price.slice(-1))) {
                return;
            }
            if (price.length > 6) {
                return;
            }
            dispatch((draft) => {
                const product = filter(draft.products, (e) => e.type === type);
                product[0].price = productPrice;
                product[0].price = price;
            });
            setDirty(true);
        },
        [dispatch, setDirty],
    );

    const checked = (type: ProductType): boolean => {
        return filter(formState.products, (e) => e.type === type).length > 0;
    };

    const getPrice = (type: ProductType): number | string => {
        const product = filter(formState.products, (e) => e.type === type);
        if (product[0]) {
            return product[0].price;
        }
        return 0;
    };

    return (
        <>
            <form id="workspace" className={styles.wrapper} onSubmit={onSubmit}>
                <div className={styles.productViewTitle}>{t('flesk.product.title')}</div>
                <div className={styles.products}>
                    <SingleProduct
                        type={ProductType.FLESK_DESK}
                        checked={checked(ProductType.FLESK_DESK)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(ProductType.FLESK_DESK, e.target.checked);
                        }}
                        dailyTariff
                        dailyPrice={getPrice(ProductType.FLESK_DESK) !== 0 ? getPrice(ProductType.FLESK_DESK) : ''}
                        dailyPriceChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            priceChange(ProductType.FLESK_DESK, e.target.value);
                        }}
                        price={
                            getPrice(ProductType.FLESK_DESK_DROP_IN) !== 0
                                ? getPrice(ProductType.FLESK_DESK_DROP_IN)
                                : ''
                        }
                        priceChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            priceChange(ProductType.FLESK_DESK_DROP_IN, e.target.value);
                        }}
                        dropInChecked={checked(ProductType.FLESK_DESK_DROP_IN)}
                        dropInChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(ProductType.FLESK_DESK_DROP_IN, e.target.checked);
                        }}
                        deskHalfDayPassesSupported={formState.deskHalfDayPassesSupported}
                        deskHalfDayPassesSupportedChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch((draft) => {
                                draft.deskHalfDayPassesSupported = e.target.checked;
                            });
                        }}
                    />
                    <SingleProduct
                        type={ProductType.FLESK_BOX}
                        checked={checked(ProductType.FLESK_BOX)}
                        price={getPrice(ProductType.FLESK_BOX) !== 0 ? getPrice(ProductType.FLESK_BOX) : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(ProductType.FLESK_BOX, e.target.checked)
                        }
                        priceChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            priceChange(ProductType.FLESK_BOX, e.target.value);
                        }}
                    />
                    <SingleProduct
                        type={ProductType.FLESK_ROOM_SMALL}
                        checked={checked(ProductType.FLESK_ROOM_SMALL)}
                        price={
                            getPrice(ProductType.FLESK_ROOM_SMALL) !== 0 ? getPrice(ProductType.FLESK_ROOM_SMALL) : ''
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(ProductType.FLESK_ROOM_SMALL, e.target.checked)
                        }
                        priceChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            priceChange(ProductType.FLESK_ROOM_SMALL, e.target.value);
                        }}
                    />
                    <SingleProduct
                        type={ProductType.FLESK_ROOM_MEDIUM}
                        checked={checked(ProductType.FLESK_ROOM_MEDIUM)}
                        price={
                            getPrice(ProductType.FLESK_ROOM_MEDIUM) !== 0 ? getPrice(ProductType.FLESK_ROOM_MEDIUM) : ''
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(ProductType.FLESK_ROOM_MEDIUM, e.target.checked)
                        }
                        priceChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            priceChange(ProductType.FLESK_ROOM_MEDIUM, e.target.value);
                        }}
                    />
                    <SingleProduct
                        type={ProductType.FLESK_ROOM_LARGE}
                        checked={checked(ProductType.FLESK_ROOM_LARGE)}
                        price={
                            getPrice(ProductType.FLESK_ROOM_LARGE) !== 0 ? getPrice(ProductType.FLESK_ROOM_LARGE) : ''
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(ProductType.FLESK_ROOM_LARGE, e.target.checked)
                        }
                        priceChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            priceChange(ProductType.FLESK_ROOM_LARGE, e.target.value);
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default ResourcesTab;
