import "flesk-webapp/src/containers/theme.css.ts.vanilla.css!=!../../../../.yarn/cache/virtual-resource-loader-npm-1.0.0-3b4565a20a-6d7cbdb6e7.zip/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?{\"source\":\"LmFicXNkaTAgewogIC0tYWJxc2RpMTogIzIzMjQ0MzsKICAtLWFicXNkaTI6ICMzZmM2N2M7CiAgLS1hYnFzZGkzOiAjMDA5NDRmOwogIC0tYWJxc2RpNDogI2VmZjFmNjsKICAtLWFicXNkaTU6ICNmZmZmZmY7CiAgLS1hYnFzZGk2OiByZ2JhKDEwMCwgMTE2LCAxNjYsIDAuMSk7CiAgLS1hYnFzZGk3OiAjOGE4YThhOwogIC0tYWJxc2RpODogIzAwMDAwMDsKICAtLWFicXNkaTk6ICNlOWU5ZWM7CiAgLS1hYnFzZGlhOiByZWQ7CiAgLS1hYnFzZGliOiAjNTU1OwogIC0tYWJxc2RpYzogIzY0NzRhNjsKICAtLWFicXNkaWQ6ICNlMGUzZWQ7CiAgLS1hYnFzZGllOiAjZmY1MTU3OzsKICAtLWFicXNkaWY6ICM2NDc0YTY7CiAgLS1hYnFzZGlnOiAjMjMyNDQzOwogIC0tYWJxc2RpaDogI2RjZGNkYzsKICAtLWFicXNkaWk6ICNiMWJhZDQ7CiAgLS1hYnFzZGlqOiAjZGVmMWUyOwogIC0tYWJxc2RpazogIzRmNWM4NSw7Cn0=\"}!../../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-42e303e22e/0/cache/@vanilla-extract-webpack-plugin-npm-1.1.0-d5c2bbdcbd-217331c592.zip/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "flesk-webapp/src/components/workspace/mySpace/WorkspaceTab.css.ts.vanilla.css!=!../../../../.yarn/cache/virtual-resource-loader-npm-1.0.0-3b4565a20a-6d7cbdb6e7.zip/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?{\"source\":\"Ll83enpveGQwIHsKICBmb250LXdlaWdodDogYm9sZDsKICBmb250LXNpemU6IDEuMjVyZW07CiAgY29sb3I6IHZhcigtLWFicXNkaTEpOwogIGZsZXgtZ3JvdzogMTsKICBmbGV4LXNocmluazogMTsKICBmbGV4LWJhc2lzOiAwOwogIG92ZXJmbG93OiBhdXRvOwp9Ci5fN3p6b3hkMSB7CiAgZm9udC13ZWlnaHQ6IGJvbGQ7CiAgZm9udC1zaXplOiAxLjc1cmVtOwogIHBhZGRpbmctdG9wOiAzcmVtOwogIGNvbG9yOiB2YXIoLS1hYnFzZGkxKTsKfQouXzd6em94ZDIgewogIGZvbnQtd2VpZ2h0OiBib2xkOwogIGZvbnQtc2l6ZTogMS43NXJlbTsKICBwYWRkaW5nLXRvcDogMnJlbTsKICBjb2xvcjogdmFyKC0tYWJxc2RpMSk7CiAgYm9yZGVyLXRvcDogc29saWQgMC4wNjNyZW0gI2IwYjBiMDsKICBtYXJnaW4tcmlnaHQ6IDJyZW07Cn0KLl83enpveGQzIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgcGFkZGluZzogMXJlbSAzcmVtIDEwcmVtIDYuMzc1cmVtOwogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWFicXNkaTYpOwp9Ci5fN3p6b3hkNCB7CiAgcGFkZGluZzogMS43NXJlbSAwcmVtIDJyZW0gMHJlbTsKICBmb250LXdlaWdodDogbm9ybWFsOwogIGxpbmUtaGVpZ2h0OiAxLjYyNTsKfQouXzd6em94ZDUgewogIHBhZGRpbmc6IDNyZW0gMHJlbSAwcmVtIDByZW07CiAgZm9udC13ZWlnaHQ6IG5vcm1hbDsKfQouXzd6em94ZDYgewogIHBhZGRpbmc6IDFyZW0gMHJlbSAycmVtIDByZW07CiAgZm9udC13ZWlnaHQ6IG5vcm1hbDsKICBkaXNwbGF5OiBmbGV4Owp9Ci5fN3p6b3hkNyB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogcm93OwogIGdhcDogMXJlbTsKICBmbGV4LXdyYXA6IHdyYXA7Cn0KLl83enpveGQ4IHsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1ncm93OiAxOwogIGZsZXgtYmFzaXM6IDMzcmVtOwp9Ci5fN3p6b3hkOSB7CiAgcGFkZGluZy10b3A6IDJyZW07CiAgZm9udC13ZWlnaHQ6IGJvbGQ7CiAgZm9udC1zaXplOiAxLjVyZW07CiAgY29sb3I6IHZhcigtLWFicXNkaTEpOwp9\"}!../../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-42e303e22e/0/cache/@vanilla-extract-webpack-plugin-npm-1.1.0-d5c2bbdcbd-217331c592.zip/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var description = '_7zzoxd4';
export var generalTitle = '_7zzoxd1';
export var inputForm = '_7zzoxd3';
export var inputSection = '_7zzoxd8';
export var langCheckboxes = '_7zzoxd6';
export var onboardingTitle = '_7zzoxd2';
export var secondLangTitle = '_7zzoxd5';
export var subTitle = '_7zzoxd9';
export var subtitleTeaser = '_7zzoxd7';
export var wrapper = '_7zzoxd0';