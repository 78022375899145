import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useState } from 'react';
import Lightbox from 'react-datatrans-light-box';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import { CreditPaket } from '../../../../types/Credits';
import { Pathname } from '../../../../types/Pathname';
import LoadingIndicator from '../../../LoadingIndicator';
import * as styles from './Purchase.css';

const DATATRANS_PRODUCTION = process.env.REACT_APP_DATATRANS_PRODUCTION || false;
const API = process.env.REACT_APP_API_URL;

const DatatransLightbox = ({
    companyId,
    costs,
    amount,
    credits,
    selectedPaket,
}: {
    companyId: number | undefined;
    costs: number;
    amount: number;
    credits: number;
    selectedPaket: CreditPaket | undefined;
}): React.ReactElement => {
    const auth = useAuth();
    const { t } = useTranslation();
    const [transactionId, setTransactionId] = useState('');
    const [lightbox, showLightbox] = useState(false);
    const [lightboxLoading, setLightboxLoading] = useState(false);

    const onCancelled: () => void = () => showLightbox(false);
    const onError: (error: { message: string; detail: string }) => void = (error) => {
        console.error('Error:', error);
        showLightbox(false);
    };
    const [searchParams] = useSearchParams();
    searchParams.set('credits', credits.toString());

    const redirectUrl = `${window.location.protocol}//${window.location.host}${
        Pathname.PURCHASES
    }?${searchParams.toString()}`;

    const initializeDataTransaction = async (): Promise<void> => {
        try {
            setLightboxLoading(true);
            const response = await fetch(`${API}/v1/admin/companies/${companyId}/abo/credits/topup-init`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify({
                    creditPaketName: selectedPaket?.paketName,
                    numberOfPakets: amount,
                    redirectUrl,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to initialize data transaction');
            }

            const responseData = await response.json();
            setTransactionId(responseData.transactionId as string);
            setLightboxLoading(false);
            showLightbox(true);
        } catch (error) {
            console.error('initializeDataTransaction error:', error);
        }
    };

    const disableBtn = (): boolean => {
        return amount === undefined || selectedPaket === undefined;
    };
    return (
        <>
            {!lightboxLoading && !lightbox && (
                <button
                    disabled={disableBtn()}
                    className={styles.blueBtn}
                    type="button"
                    onClick={initializeDataTransaction}
                >
                    {`${t('buy.credits.btn1')} für ${costs}.- ${t('buy.credits.btn2')}`}
                </button>
            )}
            {lightboxLoading && (
                <div style={{ marginTop: '2rem' }}>
                    <LoadingIndicator />
                </div>
            )}
            {lightbox && (
                <Lightbox
                    transactionId={transactionId}
                    production={DATATRANS_PRODUCTION}
                    onCancelled={onCancelled}
                    onError={onError}
                />
            )}
        </>
    );
};

export default DatatransLightbox;
