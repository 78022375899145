import { useTranslation } from '@ubique-innovation/react-translations';
import filter from 'lodash/filter';
import React from 'react';

import { Link } from 'react-router-dom';

import * as styles from './WorkspacePreview.css';

import Close from '../../images/close-white.svg';
import { Pathname } from '../../types/Pathname';
import { Workspaces } from '../../types/Workspace';

const WorkspacePreview = ({
    workspace,
    workspaceIds,
    setWorkspaceId,
    workspaceId,
    setShowWorkspace,
}: {
    workspace: Workspaces[];
    workspaceIds: number[];
    setWorkspaceId: (v: number) => void;
    workspaceId: number | undefined;
    setShowWorkspace: (v: boolean) => void;
}): React.ReactElement => {
    const { t } = useTranslation();

    const getWorkspaceTitle = (id: number | string | undefined): string => {
        if (id == null) {
            return '';
        }
        const numb = typeof id === 'string' ? parseInt(id, 10) : id;
        if (workspace.length !== 0 && numb) {
            const comp = filter(workspace, (l) => l.workspaceId === numb);
            return comp[0].title;
        }
        return id.toString();
    };

    const getImage = (id: number | string | undefined): string => {
        const numb = typeof id === 'string' ? parseInt(id, 10) : id;
        if (workspace.length !== 0 && numb) {
            const comp = filter(workspace, (l) => l.workspaceId === numb);
            return comp[0].thumbnailUrl;
        }
        return '';
    };

    return (
        <div className={styles.linkWrapper} key={workspaceId}>
            {workspaceId !== undefined ? (
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowWorkspace(false)}
                    onKeyDown={() => setShowWorkspace(false)}
                    className={styles.closeIcon}
                >
                    <img src={Close} alt="close-icon" />
                </div>
            ) : (
                <div className={styles.closeIcon} />
            )}
            <div className={styles.spaces}>
                <div className={styles.pageTitle}>{t('navigation.workspaces').toUpperCase()}</div>
                <div className={styles.btnWrapper}>
                    {workspaceIds.map((l) => (
                        <Link to={`/space/${Pathname.WORKSPACEBOOKING}`} key={l}>
                            <div
                                key={l}
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0,0,0,.5),
                                             rgba(0,0,0,.5)), url(${getImage(l)})`,
                                }}
                                className={styles.spaceDiv}
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => {
                                    setShowWorkspace(false);
                                    setWorkspaceId(l);
                                }}
                                onClick={() => {
                                    setShowWorkspace(false);
                                    setWorkspaceId(l);
                                }}
                                aria-label="Workspace"
                            >
                                <div className={styles.text}>{getWorkspaceTitle(l)}</div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WorkspacePreview;
