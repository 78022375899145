import React, { useCallback } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';

import { FormReducerDispatch } from '../../../reducers/formReducer';
import { AddressCountryShort, Contact, InvoiceRecipient, Locale } from '../../../types/Workspace';
import * as styles from './CompanyAccountTab.css';
import EditBlock, { Formats } from '../../templates/EditBlock';
import ErrorMessage from '../../templates/ErrorMessage';

const CompanyAccountTab = ({
    formInvoiceRecipient,
    formContact,
    dispatchFormInvoiceRecipient,
    dispatchFormContact,
    onInvoiceRecipientSubmit,
    onContactSubmit,
    resetInvoiceRecipient,
    resetContact,
    savingError,
    incomplete,
}: {
    formInvoiceRecipient: InvoiceRecipient;
    formContact: Contact;
    dispatchFormInvoiceRecipient: FormReducerDispatch<InvoiceRecipient>;
    dispatchFormContact: FormReducerDispatch<Contact>;
    onInvoiceRecipientSubmit: React.FormEventHandler;
    onContactSubmit: React.FormEventHandler;
    resetInvoiceRecipient: () => void;
    resetContact: () => void;
    savingError: string | undefined;
    incomplete: boolean;
}): React.ReactElement => {
    const { t } = useTranslation();

    const updateForm = useCallback(
        ({ target: { value, name, type, checked } }: React.ChangeEvent<HTMLInputElement>) => {
            const updatePath = name.split('.');
            if (type === 'checkbox') {
                dispatchFormInvoiceRecipient({
                    _path: updatePath,
                    _value: checked,
                });
                return;
            }
            if (updatePath.length === 1) {
                const [key] = updatePath;
                dispatchFormInvoiceRecipient({
                    [key]: value,
                });
            } else {
                dispatchFormInvoiceRecipient({
                    _path: updatePath,
                    _value: value,
                });
            }
        },
        [dispatchFormInvoiceRecipient],
    );

    const updateContactForm = useCallback(
        ({ target: { value, name, type, checked } }: React.ChangeEvent<HTMLInputElement>) => {
            const updatePath = name.split('.');
            if (type === 'checkbox') {
                dispatchFormContact({
                    _path: updatePath,
                    _value: checked,
                });
                return;
            }
            if (updatePath.length === 1) {
                const [key] = updatePath;
                dispatchFormContact({
                    [key]: value,
                });
            } else {
                dispatchFormContact({
                    _path: updatePath,
                    _value: value,
                });
            }
        },
        [dispatchFormContact],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.pageTitle}>{t('navigation.settings')}</div>
            {incomplete && <ErrorMessage text={t('contactdetails.error-message')} width={55} />}
            {savingError && <div className={styles.errorMessage}>{savingError}</div>}
            <form id="companyContact" className={styles.inputForm} onSubmit={onContactSubmit}>
                <EditBlock
                    id="companyContact"
                    blockTitle={t('contact.subtitle')}
                    blockSets={[
                        {
                            name: 'firstName',
                            label: t('settings.payment.information.firstName'),
                            value: formContact.firstName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateContactForm,
                        },
                        {
                            name: 'lastName',
                            label: t('settings.payment.information.lastName'),
                            value: formContact.lastName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateContactForm,
                        },
                        {
                            name: 'mail',
                            label: t('settings.payment.information.mail'),
                            value: formContact.mail,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateContactForm,
                        },
                        {
                            name: 'correspondenceLanguage',
                            label: t('settings.payment.information.correspondence'),
                            value: formContact.correspondenceLanguage,
                            required: true,
                            format: Formats.DROPDOWN,
                            options: [
                                {
                                    value: Locale.DE,
                                    label: t('flesk.language.de'),
                                },
                                {
                                    value: Locale.FR,
                                    label: t('flesk.language.fr'),
                                },
                                {
                                    value: Locale.EN,
                                    label: t('flesk.language.en'),
                                },
                            ],
                            onChange: (v: Locale) => {
                                dispatchFormContact({ correspondenceLanguage: v });
                            },
                        },
                    ]}
                    reset={resetContact}
                />
            </form>

            <form id="invoiceRecipient" className={styles.inputForm} onSubmit={onInvoiceRecipientSubmit}>
                <EditBlock
                    id="invoiceRecipient"
                    blockTitle={t('account.invoiceAddress')}
                    blockSets={[
                        {
                            name: 'businessName',
                            label: t('settings.payment.information.companyname'),
                            value: formInvoiceRecipient.businessName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateForm,
                        },
                        {
                            name: 'personFirstName',
                            label: t('settings.payment.information.firstName'),
                            value: formInvoiceRecipient.personFirstName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateForm,
                        },
                        {
                            name: 'personLastName',
                            label: t('settings.payment.information.lastName'),
                            value: formInvoiceRecipient.personLastName,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateForm,
                        },
                        {
                            name: 'mail',
                            label: t('settings.payment.information.mail'),
                            value: formInvoiceRecipient.mail,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateForm,
                        },
                        {
                            name: 'street',
                            label: t('settings.payment.information.contactadress'),
                            value: formInvoiceRecipient.street,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateForm,
                        },
                        {
                            name: 'postCode',
                            label: t('settings.payment.information.contactpostcode'),
                            value: formInvoiceRecipient.postCode,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateForm,
                        },
                        {
                            name: 'city',
                            label: t('settings.payment.information.contactcity'),
                            value: formInvoiceRecipient.city,
                            required: true,
                            format: Formats.INPUT,
                            onChange: updateForm,
                        },
                        {
                            name: 'contactCountry',
                            label: t('settings.payment.information.contactcountry'),
                            value: formInvoiceRecipient.country,
                            required: true,
                            format: Formats.DROPDOWN,
                            options: Object.keys(AddressCountryShort).map((country) => ({
                                value: country,
                                label: country,
                            })),
                            onChange: (country: AddressCountryShort) => {
                                dispatchFormInvoiceRecipient({ country });
                            },
                        },
                    ]}
                    reset={resetInvoiceRecipient}
                />
            </form>
        </div>
    );
};

export default CompanyAccountTab;
