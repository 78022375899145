import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useCallback, useEffect } from 'react';
import close from '../../images/close.svg';
import downloadIcon from '../../images/download-line.svg';
import InstructionFr from '../../files/Flesk_AdminTool_FR_2209.pdf';
import InstructionDE from '../../files/Flesk_AdminTool_DE_2209.pdf';
import * as styles from './ContactSupportTab.css';
import SectionTitle from '../../components/templates/SectionTitle';

const ContactSupportTab = ({ setShowContact }: { setShowContact: (d: boolean) => void }): React.ReactElement => {
    const { t } = useTranslation();

    const escFunction = useCallback(
        (event) => {
            if (event.key === 'Escape') {
                setShowContact(false);
            }
        },
        [setShowContact],
    );

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
    }, [escFunction]);

    return (
        <div className={styles.background}>
            <div className={styles.contactDiv}>
                <div className={styles.wrapper}>
                    <div className={styles.slackInfo}>
                        <div
                            className={styles.closeBtn}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => setShowContact(false)}
                            onClick={() => setShowContact(false)}
                        >
                            <img src={close} alt="close button" />
                        </div>
                        <div className={styles.title}>{t('navigation.contactSupport')}</div>
                        {/* <>
                                <div>{t('contact.slack.title')}</div>
                                <p className={styles.slackDesc}>{t('contact.slack.desc')}</p>
                                <a
                                    className={styles.navLink}
                                    href="https://join.slack.com/t/fleskspace/shared_invite/zt-15ylimw3a-ISiLyRjTY50IclH3OYSOwA"
                                >
                                    <img className={styles.navIcon} src={icon} alt="" />
                                    <div className={styles.navListDiv}>{t('contact.slack.link')}</div>
                                </a>
                            </> */}
                        <SectionTitle title={t('contact.downloads.title')} />
                        <a
                            className={styles.downloadBtn}
                            type="button"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={InstructionDE}
                            download={t('support.pdf.label.de')}
                        >
                            <div className={styles.downloadDiv}>{t('support.pdf.label.de')}</div>
                            <img className={styles.downloadIcon} src={downloadIcon} alt="" />
                        </a>
                        <a
                            className={styles.downloadBtn}
                            type="button"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={InstructionFr}
                            download={t('support.pdf.label.fr')}
                        >
                            <div className={styles.downloadDiv}>{t('support.pdf.label.fr')}</div>
                            <img className={styles.downloadIcon} src={downloadIcon} alt="" />
                        </a>
                    </div>
                    <div className={styles.contactWrapper}>
                        <div className={styles.contactSubtitle}>{t('contact.subtitle')}</div>
                        <div className={styles.contactAdress}>{t('contact.flesk.address')}</div>
                        <a className={styles.fleskLink} href="mailto:feedback@flesk.ch">
                            <div className={styles.navListDiv}>{t('contact.support.mail')}</div>
                        </a>
                        <a
                            className={styles.fleskLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.flesk.ch"
                        >
                            <div className={styles.navListDiv}>{t('contact.flesk.link')}</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSupportTab;
