import React, { useCallback, useEffect, useReducer } from 'react';
import AccountTab from '../../components/workspace/account/AccountTab';
import formReducer, { FormReducerArgs } from '../../reducers/formReducer';
import { Beneficiary, BookingsInfo, Contact } from '../../types/Workspace';

const Account = ({
    beneficiary,
    contact,
    bookingInfo,
    onBeneficiarySave,
    onContactSave,
    onBookingSave,
    savingError,
    incomplete,
}: {
    beneficiary: Beneficiary;
    contact: Contact;
    bookingInfo: BookingsInfo;
    onBeneficiarySave: (w: Beneficiary) => void;
    onContactSave: (w: Contact) => void;
    onBookingSave: (w: BookingsInfo) => void;
    savingError: string | undefined;

    incomplete: boolean;
}): React.ReactElement => {
    const [formBeneficiary, dispatchFormBeneficiary] = useReducer<
        (state: Beneficiary, updateArgs: FormReducerArgs<Beneficiary>) => Beneficiary
    >(formReducer, beneficiary);

    const [formContact, dispatchFormContact] = useReducer<
        (state: Contact, updateArgs: FormReducerArgs<Contact>) => Contact
    >(formReducer, contact);

    const [formBooking, dispatchFormBooking] = useReducer<
        (state: BookingsInfo, updateArgs: FormReducerArgs<BookingsInfo>) => BookingsInfo
    >(formReducer, bookingInfo);

    const resetBeneficiary = useCallback(() => {
        dispatchFormBeneficiary(beneficiary);
    }, [beneficiary]);

    const resetContact = useCallback(() => {
        dispatchFormContact(contact);
    }, [contact]);

    const resetBooking = useCallback(() => {
        dispatchFormBooking(bookingInfo);
    }, [bookingInfo]);

    const handleBeneficiarySubmit = (): void => {
        if (formBeneficiary.mwstPflichtig === false) {
            formBeneficiary.mwstNumber = null;
        }
        onBeneficiarySave(formBeneficiary);
    };

    const handleContactSubmit = (): void => {
        onContactSave(formContact);
    };

    const handleBookingSubmit = (): void => {
        onBookingSave(formBooking);
    };

    useEffect(() => {
        resetContact();
    }, [contact, resetContact]);

    useEffect(() => {
        resetBeneficiary();
    }, [beneficiary, resetBeneficiary]);

    return (
        <AccountTab
            formBeneficiary={formBeneficiary}
            formContact={formContact}
            formBooking={formBooking}
            dispatchFormBeneficiary={dispatchFormBeneficiary}
            dispatchFormContact={dispatchFormContact}
            dispatchFormBooking={dispatchFormBooking}
            onBeneficiarySubmit={handleBeneficiarySubmit}
            onContactSubmit={handleContactSubmit}
            onBookingSubmit={handleBookingSubmit}
            resetBeneficiary={resetBeneficiary}
            resetContact={resetContact}
            resetBooking={resetBooking}
            savingError={savingError}
            incomplete={incomplete}
        />
    );
};

export default Account;
