import React from 'react';
import * as styles from './FleskButton.css';

const FleskButton = ({
    label,
    darkBtn,
    onClick,
    submit,
    disabled,
    id,
}: {
    label: string;
    darkBtn: boolean;
    onClick?: () => void;
    submit?: boolean;
    disabled?: boolean;
    id?: string;
}): React.ReactElement => {
    return (
        <>
            <button
                className={styles.btn({ dark: darkBtn })}
                type={submit ? 'submit' : 'button'}
                onClick={onClick}
                disabled={disabled}
                form={id}
            >
                {label}
            </button>
        </>
    );
};

FleskButton.defaultProps = {
    onClick: undefined,
    submit: false,
    disabled: false,
    id: '',
};

export default FleskButton;
